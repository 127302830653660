

const broadleafevergreens = {
  boxwoods:  [
    {
        id: 1,
        name: "Wintergreen Boxwood",
        scientificName: "Buxus microphylla var. japonica ‘Wintergreen’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/boxwood-1235076_1920.jpg",
        description: "Wintergreen boxwood is a hardy, low-maintenance evergreen shrub that thrives in New Mexico with proper care. It prefers moderate water but is relatively drought-tolerant once established, making it suitable for Albuquerque’s dry climate. This shrub does best in partial to full sun but benefits from afternoon shade to protect against intense summer heat. Regular pruning maintains its shape, making it ideal for hedges, borders, or foundation plantings, while mulch helps retain moisture and regulate soil temperature.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-9',
        matureSize: "3' x 3'",
      },
    {
        id: 2,
        name: "Green Mountain Boxwood",
        scientificName: "Buxus x ‘Green Mountain’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/boxwood-6731662_1920.jpg",
        description: "Green Mountain Boxwood is an upright, cone-shaped evergreen shrub that adds year-round structure to New Mexico gardens. Its dense, bright green foliage maintains color well through winter, though slight bronzing may occur in colder months. This slow-growing variety is perfect for hedges, topiaries, or foundation plantings, with minimal pruning needed to maintain its natural shape. Mulching around the base helps retain moisture and protect roots from temperature extremes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-9',
        matureSize: "4' x 4'",
      },
    {
        id: 3,
        name: "Green Velvet Boxwood",
        scientificName: "Buxus microphylla 'Green Velvet'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/boxwood.jpg",
        description: "Green Velvet Boxwood is a versatile, evergreen shrub that thrives in New Mexico’s diverse climates. Known for its dense, rounded form and rich green foliage, it provides year-round structure and privacy to gardens. It prefers partial to full sun and well-drained soil, requiring moderate watering once established. With its compact size, it’s perfect for low-maintenance hedges, borders, or foundation plantings, offering both elegance and durability in xeriscapes or traditional landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '4-9',
        matureSize: "3' x 3'",
      },
  ],
  euonomys:  [
    {
        id: 4,
        name: "Chollipo Euonymus",
        scientificName: "Euonymus japonicus 'Chollipo",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/euoymus-japonicus-silverqueen.jpg",
        description: "Chollipo euonymus is a tough, drought-tolerant shrub well-suited for New Mexico’s arid climate. Its variegated green and creamy yellow foliage adds year-round interest, making it a great choice for hedges, borders, or foundation plantings. This low-maintenance evergreen thrives in full sun to partial shade and adapts to various soil types, requiring little water once established. Resistant to heat, wind, and pests, Chollipo euonymus is an excellent option for xeriscaping and low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-9',
        matureSize: "4' x 4'",
      },
    {
        id: 5,
        name: "Variegated Boxleaf Euonymus",
        scientificName: "Euonymus japonicus 'Variegatus'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/evergreen-spindle-7337019_1920.jpg",
        description: "Variegated Boxleaf Euonymus is an attractive evergreen shrub ideal for New Mexico gardens. Its glossy, dark green leaves are edged with creamy white or yellow, providing year-round color and contrast. It thrives in full sun to partial shade and prefers well-drained soil, requiring moderate watering once established. This low-maintenance shrub’s compact growth makes it perfect for borders, foundation plantings, or containers, adding elegance and texture to both xeriscapes and more traditional landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-9',
        matureSize: "3' x 3'",
      },
    {
        id: 6,
        name: "Manhattan Euonymus",
        scientificName: "Euonymus kiautschovicus ‘Manhattan’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/Euonymus_kiautschovicus_Manhattan_Attributed.jpg",
        description: "Manhattan Euonymus is a fast-growing, broadleaf evergreen valued for its lush, deep green foliage and adaptability. It forms a dense, mounded shape, making it ideal for hedges, windbreaks, or privacy screens. This tough shrub tolerates urban conditions well and can be pruned to maintain its size and shape. Mulching helps retain moisture and supports healthy root development in New Mexico’s climate.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-9',
        matureSize: "6' x 6'",
      },
  ],
  photinias:  [
    {
        id: 7,
        name: "Red Tip Photinia",
        scientificName: "Photinia x fraseri",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/photinia.jpg",
        description: "Red Tip Photinia, sometimes known as Red Robin Photinia, is a fast-growing evergreen shrub known for its striking red new growth that matures to glossy green. It’s a popular choice for hedges, screens, or specimen plantings due to its dense foliage and tolerance for pruning. While highly adaptable, good air circulation helps prevent leaf spot, and mulching supports healthy root development. This is a great choice for shrubbery in New Mexico.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '7-9',
        matureSize: "10' x 10'",
      },
    {
        id: 10,
        name: "Chinese Photinia",
        scientificName: "Photinia serratifolia",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/chinese-photinia.jpg",
        description: "Chinese Photinia is a robust, evergreen shrub with large, leathery green leaves and reddish-bronze new growth. Taller and more tree-like than other photinias, it works well as a privacy screen, windbreak, or specimen plant. Its dense foliage provides year-round interest, and occasional pruning helps maintain its shape. Mulching supports moisture retention and root health in New Mexico’s dry climate.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '7-9',
        matureSize: "8' x 8'",
      },
  ],
  nandinas:  [
    {
        id: 11,
        name: "Heavenly Bamboo Nandina",
        scientificName: "Nandina domestica",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/nandina2.jpg",
        description: "Heavenly Bamboo is a versatile, semi-evergreen shrub valued for its delicate, bamboo-like foliage that changes color with the seasons. In New Mexico gardens, its leaves transition from bright green in spring to fiery red and orange in fall and winter, adding year-round interest. Clusters of white flowers in summer give way to vibrant red berries that attract birds. This low-maintenance shrub works well in borders, foundation plantings, or as an accent, with occasional pruning helping to maintain its shape. Mulching aids in moisture retention and root protection in dry conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '6-9',
        matureSize: "5' x 5'",
      },
    {
        id: 12,
        name: "Firepower Nandina",
        scientificName: "Nandina domestica ‘Firepower’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/heavenly-bamboo-8514863_1920.jpg",
        description: "Firepower Nandina is a compact, dwarf variety prized for its brilliant red foliage in fall and winter. Its lush green leaves turn fiery shades of red and orange as temperatures drop, providing year-round interest without requiring pruning. Ideal for borders, mass plantings, or containers, this low-maintenance shrub thrives with occasional watering and benefits from mulch to retain moisture.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '6-9',
        matureSize: "2' x 3'",
      },
    {
        id: 13,
        name: "Nandina Flirt",
        scientificName: "Nandina domestica ‘Murasaki’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/nandina.jpg",
        description: "Flirt Nandina is a smaller, more controlled variety that features deep red new growth, which lasts longer than other nandinas before maturing to dark green. Its compact size makes it perfect for edging, foundation plantings, or containers. With minimal care required, it thrives in well-drained soil, and a layer of mulch helps conserve moisture in New Mexico’s dry climate.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '6-9',
        matureSize: "3' x 3'",
      },

  ],
  mahogonies: [
    {
      id: 14,
      name: "Curl-leaf Mountain Mahogany",
      scientificName: "Cercocarpus ledifolius",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/Cercocarpus_ledifolius_8219.jpg",
      description: "The Curl-leaf Mountain Mahogany is a hardy, evergreen shrub that thrives in New Mexico’s arid conditions. Known for its distinctive twisting, leathery leaves and silvery-gray foliage, it provides year-round interest, especially during the winter months. This drought-tolerant shrub offers beautiful, fluffy seed tails that add texture to the landscape. Ideal for low-maintenance, xeriscape gardens, it works well in rocky, well-drained soils and full sun, making it perfect for those seeking a rugged yet attractive plant for dry landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: '5-9',
      matureSize: "15' x 15'",
    },
    {
      id: 15,
      name: "Alderleaf Mountain Mahogany",
      scientificName: "Cercocarpus Montanus",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/Cercocarpus_montanus_kz07.jpg",
      description: "Alderleaf Mountain Mahogany is a deciduous shrub that adds texture and structure to New Mexico gardens with its oval, dark green leaves and silky, feathery seed heads. This variety thrives in well-drained soils and full sun, offering both wildlife value and low-maintenance beauty. Its dense, upright form provides great wind protection and erosion control, making it a great choice for slopes or challenging landscapes. Alderleaf is highly drought-tolerant once established, making it well-suited to the dry, high-desert environment.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: '6-9',
      matureSize: "10' x 12'",
    },
    {
      id: 16,
      name: "Hairy Mountain Mahogany",
      scientificName: "CCercocarpus breviflorus",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/hairy-mountain-mahogany.jpg",
      description: "Hairy Mountain Mahogany is a small, evergreen shrub that thrives in harsh, dry conditions. Known for its smooth, dark green leaves and hair-like seed tails that add elegance to the plant, it can grow as a shrub or small tree. This variety is perfect for xeriscape landscapes in New Mexico, requiring little water once established. Its hardy nature makes it ideal for slopes, dry hillsides, and rocky terrain. Hair Mountain Mahogany is also valued for its windbreak potential and wildlife habitat benefits.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: '5-9',
      matureSize: "12' x 15'",
    },
  
  ],

  cotoneasters:  [
    {
        id: 17,
        name: "Parney Cotoneaster",
        scientificName: "Cotoneaster lacteus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/parney-cotoneaster.jpg",
        description: "Parney Cotoneaster  is a hardy, drought-tolerant shrub perfect for New Mexico gardens. Thriving in full sun with well-draining soil, it features dark green foliage, spring flowers, and bright red winter berries that attract birds. Its arching branches create a graceful, layered look, making it ideal for hedges, windbreaks, or accent plantings. Low-maintenance and heat-resistant, it’s a great choice for water-wise landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: '7-9',
        matureSize: "12' x 12'",
      },
    {
        id: 18,
        name: "Coral Beauty Cotoneaster",
        scientificName: "Cotoneaster dammeri",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/dammeri-cotoneaster.jpg",
        description: "Dammeri Cotoneaster is a tough, low-growing evergreen shrub perfect for New Mexico gardens. Thriving in full sun to partial shade, it spreads quickly, forming a dense mat of glossy green leaves that help with erosion control. In spring, it produces small white flowers, followed by bright red berries in fall that attract birds. Drought-tolerant and low-maintenance, it’s an excellent choice for ground cover, slopes, or borders in water-wise landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: '5-10',
        matureSize: "2' x 6'",
      },
    {
        id: 19,
        name: "Rockspray Cotoneaster",
        scientificName: "Cotoneaster horizontalis",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/cotoneaster-4506645_1920.jpg",
        description: "Rockspray Cotoneaster is a hardy, drought-tolerant shrub well-suited to New Mexico gardens. Its distinctive branching pattern creates a striking, layered look, making it ideal for ground cover, slopes, or cascading over walls. In spring, it produces small pinkish-white flowers, followed by bright red berries in fall that attract birds. Thriving in full sun with well-draining soil, this low-maintenance shrub adds year-round interest to water-wise landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: '5-7',
        matureSize: "2' x 6'",
      },
  ],
  burningbush:  [
    {
        id: 20,
        name: "Fire Ball Burning Bush",
        scientificName: "Euonymus alatus ‘Fireball’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/burning%20bush.jpg",
        description: "Fireball Burning Bush is a compact, deciduous shrub renowned for its stunning red fall foliage. This variety offers a burst of color as its leaves turn vibrant red in autumn, creating a dramatic focal point in the garden. Growing to about 3-4 feet tall, it's perfect for borders, small hedges, or as an accent plant. Minimal pruning is needed, and mulching helps retain moisture and protect roots from temperature extremes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-8",
        matureSize: "5' x 5'",
      },
    {
        id: 21,
        name: "Dwarf Burning Bush",
        scientificName: "Euonymus alatus ‘Fireball’",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/burning-bush-2638896_1920.jpg",
        description: "Dwarf Burning Bush is a smaller version of the classic burning bush, reaching about 3-4 feet in height. It offers the same fiery red foliage in the fall, but with a more compact and manageable size, making it ideal for smaller gardens, containers, or as a low hedge. This shrub thrives in well-draining soil, and mulching helps keep the roots cool and moist in New Mexico’s dry climate.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-8",
        matureSize: "3' x 3'",
      },

  ],
  barberries: [
    {
      id: 23,
      name: "Crimson Pygmy Barberry",
      scientificName: "Berberis thunbergii ‘Crimson Pygmy’",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/berberis-7422929_1920.jpg",
      description: "Crimson Pygmy Barberry is a compact, deciduous shrub known for its deep red-purple foliage that provides year-round color, especially in the fall when it intensifies. Reaching about 2 feet in height, it’s perfect for low hedges, borders, or containers. This low-maintenance variety is drought-tolerant once established and thrives in well-drained soil. Mulching helps retain moisture and protect roots from extreme temperatures.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-8",
      matureSize: "2' x 3'",
  },
    {
      id: 24,
      name: "Orange Rocket Barberry",
      scientificName: "Berberis thunbergii ‘Orange Rocket’",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/barberry-3733393_1920.jpg",
      description: "Orange Rocket Barberry  is an upright, narrow shrub that stands out with its striking orange-red foliage in spring and summer, transitioning to a vibrant red in fall. Growing to about 3-4 feet tall, it makes a bold statement in gardens as a specimen plant or in borders. This hardy shrub is drought-tolerant and requires minimal care, with mulching helping to retain moisture and regulate soil temperature.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-8",
      matureSize: "4' x 1.5'",
  },
    {
      id: 25,
      name: "Sunjoy Gold Barberry",
      scientificName: "Berberis thunbergii 'Sunjoy Gold'",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/barberry-6623136_1920.jpg",
      description: "Sunjoy Gold Barberry is a bright and striking shrub that features golden-yellow foliage, which turns to orange and red in the fall. Growing to around 3 feet tall, it adds color and texture to gardens, working well as a border, accent, or low hedge. This hardy, low-maintenance shrub thrives in full sun and benefits from mulching to conserve moisture and protect its roots in dry climates.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-8",
      matureSize: "3' x 3'",
  },
    {
      id: 26,
      name: "Algerita",
      scientificName: "Berberis trifoliolata",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/640px-Berberis_trifoliolata_berries.jpg",
      description: "Algerita is a hardy, evergreen shrub native to the southwestern United States, including New Mexico. It is characterized by its spiny, dark green leaves and small yellow flowers that bloom in early spring, followed by bright red berries in the fall. Algerita thrives in dry, rocky soil and is highly drought-tolerant once established, making it ideal for low-maintenance landscapes or xeriscaping. Its compact, rounded form makes it a great choice for borders, hedges, or wildlife-friendly plantings. Mulching can help retain moisture and protect the roots during extreme temperatures.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "7-9",
      matureSize: "4' x 4'",
      native: true
  },

  ],

  others:  [

      {
        id: 28,
        name: "Cherry Laurel",
        scientificName: "Prunus laurocerasus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/cherry-laurel-5139882_1920.jpg",
        description: "Cherry Laurel is a versatile, evergreen shrub with glossy, dark green leaves that provide year-round interest. Known for its white, fragrant flower spikes that appear in spring, followed by dark berries in the fall, this shrub makes an excellent choice for hedges, screens, or foundation plantings. Growing up to 10 feet tall, it thrives in shaded or partially shaded areas, though it can tolerate full sun in cooler climates. Regular watering and mulching can help retain moisture and protect the roots in New Mexico’s dry conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "10' x 8'",
      },
    {
        id: 29,
        name: "Pyracantha",
        scientificName: "Pyracantha coccinea",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/firethorn-5758151_1920.jpg",
        description: "Pyracantha, commonly known as firethorn, is a vigorous, evergreen shrub with spiny branches and dense foliage. It produces clusters of white flowers in the spring, followed by vibrant red, orange, or yellow berries in the fall that attract birds. This hardy shrub can be used for hedges, screens, or as a cascading ground cover. It thrives in full sun and well-drained soil, and once established, it’s drought-tolerant. Pyracantha also benefits from occasional pruning to maintain its shape and encourage better flowering and berry production. Mulching around the base helps conserve moisture and protect roots from extreme temperatures.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "6-9",
        matureSize: "10' x 10'",
      },
    {
        id: 30,
        name: "Waxleaf Privet",
        scientificName: "Ligustrum japonicum",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/waxleaf-privet.jpg",
        description: "Waxleaf Privet is a versatile, fast-growing evergreen shrub known for its dense, dark green foliage and fragrant white flowers that bloom in late spring to early summer. It produces small, black berries in the fall, which are attractive to birds. Reaching up to 8 feet tall, it’s commonly used for hedges, screens, or foundation plantings. While it thrives in full sun to partial shade, Waxleaf Privet is adaptable to various soil types and is relatively low-maintenance. Mulching around its base helps retain moisture, and regular pruning keeps it in shape and encourages healthy growth.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "7-9",
        matureSize: "8' x 6'",
      },
      



  ],
  

}

export default broadleafevergreens;
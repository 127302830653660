const floweringshrubdata = {
    butterflybushes: [
      {
        id: 1,
        name: "Black Knight Butterfly Bush",
        scientificName: "Buddleia davidii 'Black Knight'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/butterflybush-dark-purple.jpg",
        description: "With its deep purple, almost velvety blooms, Black Knight is one of the most striking butterfly bushes for New Mexico gardens. Its rich color contrasts beautifully with desert landscapes, and its long, fragrant flower spikes attract butterflies, hummingbirds, and bees all season. This fast-growing shrub benefits from occasional pruning to maintain shape and encourage fresh blooms, making it a bold yet low-maintenance addition to pollinator-friendly gardens and one of Jericho Nursery's best sellers.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "6' x 6'",
      },
      {
        id: 2,
        name: "Pink Delight Butterfly Bush",
        scientificName: "Buddleia davidii 'Pink Delight'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/pink-butterflybush.jpg",
        description: "Known for its large, bright pink flower clusters, Pink Delight brings vibrant color and a sweet fragrance to any garden. It thrives in dry conditions and blooms profusely from summer into fall, providing a long-lasting nectar source for pollinators. With its graceful, arching branches and silvery-green foliage, this variety adds soft texture and movement to landscapes while requiring minimal upkeep.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "6' x 6'",
      },
      {
        id: 3,
        name: "Hot Raspberry Butterfly Bush",
        scientificName: "Buddleia davidii 'Tobud1202' PPAF",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/raspberry-butterflybush.jpg",
        description: "A standout for its bold reddish-pink blooms, Hot Raspberry offers a unique color variation among butterfly bushes. Its compact, upright habit makes it ideal for smaller spaces or container plantings, while its flowers draw in butterflies and hummingbirds throughout the season. From Las Cruces to Albuqueruque, butterfly bushes are drought-tolerant and easy to care for, adding a touch of color to any lush green yard or low-water xeric garden.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "6' x 6'",
      },
    
    ],
  
    indianhawthornes: [
      {
        id: 4,
        name: "Pink Lady Indian Hawthorn",
        scientificName: "Rhaphiolepis indica 'Pink Lady'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/indian-hawthorn-foliage.jpg",
        description: "Pink Lady Indian Hawthor is a hardy evergreen shrub ideal for New Mexico’s dry climate. It thrives in full sun with well-drained soil and requires minimal watering once established. In spring, it produces fragrant pink flowers that attract pollinators, contrasting beautifully with its glossy green leaves. Cold-tolerant but best in protected spots, its compact, rounded form makes it perfect for borders, foundation plantings, or containers.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "7-10",
        matureSize: "3' x 3'",
      },
        {
          id: 5,
          name: "Spring Sonata Indian Hawthorn",
          scientificName: "Rhaphiolepis indica 'Wilcor' PP17972",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Indian_Hawthorn_Bloom.jpg",
          description: "A reliable evergreen shrub, Indian Hawthorn is valued for its glossy green leaves and clusters of fragrant pink or white flowers in spring. Its compact growth habit makes it ideal for low hedges, foundation plantings, or accent shrubs. Drought-tolerant and resistant to pests, it requires little maintenance beyond occasional shaping, making it a great choice for New Mexico’s dry landscapes.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "3' x 3'",
        }, 
        {
          id: 6,
          name: "Snow White Indian Hawthorn",
          scientificName: "Rhaphiolepis indica 'Snow White' ",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Indian_Hawthorn_white.jpg",
          description: "A classic variety with abundant white blooms, Snow White Indian Hawthorn brings elegance to any garden. Its dark green foliage provides contrast for the bright flowers, which attract pollinators in spring. Naturally rounded and slow-growing, it requires little pruning, making it a perfect choice for foundation plantings, borders, or decorative containers in New Mexico’s climate.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "3' x 3'",
        },
      
      ],
  
    potentillas: [
        {
          id: 7,
          name: "Goldfinger Potentilla",
          scientificName: "Potentilla fruticosa 'Goldfinger'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/potentilla.jpg",
          description: "Goldfinger Potentilla offers a stunning display of bright yellow flowers from spring to fall, making it a standout in New Mexico gardens and one of our favorites here at Jericho Nursery. This low-growing shrub has a compact, rounded shape and fine-textured, gray-green foliage that complements its vibrant blooms. Highly drought-tolerant once established and resistant to pests, it requires minimal maintenance beyond occasional pruning, making it an excellent choice for colorful borders or low-maintenance landscaping in arid climates.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "3-7",
          matureSize: "2' x 3'",
        },
        {
          id: 8,
          name: "McKays White Potentilla",
          scientificName: "Potentilla fruticosa 'McKay's White'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/white-potentillas-6471446_1920.jpg",
          description: "McKay’s White Potentilla stands out with its pure white blooms that create a striking contrast against its dark green foliage. This low-maintenance shrub is well-suited for dry landscapes, offering a long blooming season from spring through fall. It has a compact, rounded form that makes it perfect for borders, ground covers, or mass plantings, and it’s highly drought-tolerant and resistant to pests, ensuring lasting beauty with minimal care.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "3-7",
          matureSize: "2' x 3'",
        },
        {
          id: 9,
          name: "Happy Face Pink Paradise Potentilla ",
          scientificName: "Potentilla fruticosa HAPPY FACE 'Kupinpa'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/pink-potentilla.jpg",
          description: "With its vibrant pink flowers and tidy, rounded growth habit, Happy Face Pink Paradise brings year-round interest and color to New Mexico gardens. This compact shrub thrives in dry conditions and blooms profusely from spring to fall, attracting pollinators with its sweet fragrance. Ideal for low-maintenance landscapes, it requires little more than occasional trimming to maintain its neat appearance, making it a perfect addition to Southwestern gardens.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "3-7",
          matureSize: "2' x 3'",
        },
      
      ],
      salvias: [
        {
          id: 10,
          name: "Mexican Blue Sage",
          scientificName: "Salvia chamaedryoides",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Salviachamaedryoides.jpg",
          description: "Mexican Blue Sage is a drought-tolerant perennial that thrives in New Mexico’s hot, dry climate. It features striking blue flowers from spring to fall, attracting bees, butterflies, and hummingbirds. With silvery-green foliage and a compact, mounding habit, it’s perfect for xeriscapes, borders, or rock gardens. Thriving in full sun and well-drained soil, this low-maintenance sage adds vibrant color and texture to any landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "3' x 4'",
        },
        {
          id: 11,
          name: "Autumn Sage",
          scientificName: "Salvia greggii",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/autumn-sage.jpg",
          description: "Autumn Sage is a hardy, drought-tolerant perennial perfect for New Mexico gardens. Blooming from spring to fall, it produces vibrant red, pink, or purple flowers that attract hummingbirds and pollinators. Thriving in full sun and well-drained soil, it requires minimal water once established. Its aromatic foliage and compact growth make it ideal for borders, xeriscapes, or wildlife gardens, adding long-lasting color to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "6-9",
          matureSize: "2' x 3'",
        },
        {
          id: 12,
          name: "Meadow Sage",
          scientificName: "Salvia nemorosa",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/meadow-sage.jpg",
          description: "Meadow Sage is a drought-tolerant perennial that thrives in New Mexico’s sunny, arid conditions. It produces striking spikes of purple-blue flowers from late spring through summer, attracting bees, butterflies, and hummingbirds. With its aromatic foliage and low water needs, it’s perfect for xeriscaping, borders, or pollinator gardens. This hardy plant withstands heat, poor soil, and occasional cold snaps, making it a reliable, low-maintenance choice for Southwest landscapes.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "4-8",
          matureSize: "1.5' x 2'",
        },
        {
          id: 13,
          name: "Rosemary",
          scientificName: "Salvia rosmarinus",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/rosemary-4912663_1920.jpg",
          description: "Rosemary is a hardy, drought-tolerant evergreen herb that thrives in New Mexico’s arid climate. It prefers full sun and well-drained soil, requiring minimal water once established. With fragrant, needle-like leaves and small blue flowers that attract pollinators, it’s both ornamental and useful for cooking. Its compact, woody growth makes it perfect for borders, xeriscapes, or container gardens, adding year-round greenery and resilience to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "3' x 3'",
        },
      
      ],
      texassage: [
        {
          id: 14,
          name: "Compact Texas Sage",
          scientificName: "Leucophyllum frutescens 'Compacta'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/purple-texas-sage.jpg",
          description: "Compact Texas Sage is a hardy, drought-tolerant shrub perfect for New Mexico’s arid climate. It thrives in full sun with well-drained soil, requiring little water once established. Its silvery-green foliage contrasts beautifully with its vibrant purple flowers, which bloom after summer rains and attract pollinators. With a dense, rounded form, it’s ideal for low-maintenance hedges, borders, or xeriscapes, adding year-round interest to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "3' x 3'",
        },
        {
          id: 15,
          name: "San Antonio Rose Texas Sage",
          scientificName: "Leucophyllum frutescens 'San Antonio Rose'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/pink-texasa-sage.jpg",
          description: "San Antonio Rose Texas Sage is a heat- and drought-tolerant shrub ideal for New Mexico’s dry climate. It features silvery-green foliage and bursts of deep pink flowers that appear after summer rains, attracting pollinators. Thriving in full sun with well-drained soil, it requires little maintenance or watering once established. Its dense, rounded form makes it perfect for hedges, borders, or xeriscapes, adding year-round beauty to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "4' x 4'",
        },
        {
          id: 16,
          name: "Silver Dream Texas Sage",
          scientificName: "Leucophyllum frutescens 'Silver Dream'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/leucophyllum-4932726_1920.jpg",
          description: "Silver Dream Texas Sage is a drought-tolerant shrub ideal for New Mexico’s arid climate. It features striking silvery-white foliage and produces soft lavender-purple flowers after summer rains, attracting pollinators. Thriving in full sun with well-drained soil, it requires little water and maintenance once established. Its compact, rounded shape makes it perfect for hedges, borders, or xeriscapes, adding year-round texture and color to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-10",
          matureSize: "4' x 4'",
        },
      ],
      
      lilacs: [
        {
          id: 17,
          name: "Miss Kim Lilac",
          scientificName: "Syringa pubescens subsp. patula 'Miss Kim'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/misskim-lilac.jpg",
          description: "The Miss Kim Lilac is a compact, fragrant shrub that blooms profusely with lavender-blue flowers in late spring. Known for its cold hardiness and compact size, it fits well in smaller gardens or as an ornamental hedge. This variety has a strong resistance to pests and diseases, and its dense, rounded form offers seasonal beauty with minimal maintenance. Its lovely fragrance attracts pollinators, making it a perfect addition to any Southwestern garden.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "3-7",
          matureSize: "5' x 5'",
        },
        {
          id: 18,
          name: "Ludwig Spaeth Lilac",
          scientificName: "Syringa pubescens subsp. patula 'Miss Kim'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/lilac.jpg",
          description: "With deep purple blooms that stand out against dark green foliage, Ludwig Spaeth Lilac offers a bold pop of color in the spring. Its rich fragrance fills the air, attracting both pollinators and gardeners alike. This variety thrives in well-drained soil and requires little care once established, making it a great option for New Mexico gardeners seeking an easy-to-maintain shrub with striking seasonal appeal.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "3-7",
          matureSize: "8' x 6'",
        },
        {
          id: 19,
          name: "Alba Lilac",
          scientificName: "Syringa vulgaris 'Alba'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/alba-lilac.jpg",
          description: "Alba Lilac is known for its classic white flowers that fill the air with a sweet, nostalgic fragrance every spring. This variety offers a striking contrast against its dark green foliage and grows in a compact, upright form. Hardy and easy to grow, Alba is well-suited to New Mexico’s climate and requires minimal care, making it a perfect choice for gardeners looking to add a touch of elegance and fragrance to their landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "3-7",
          matureSize: "10' x 8'",
        },
        {
          id: 20,
          name: "Tinkerbell Bailbelle Lilac",
          scientificName: "Syringa 'Bailbelle'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/tinkerbell-lilac.jpg",
          description: "A unique and compact variety, the Tinkerbell Bailbelle Lilac features lavender-pink flowers that bloom in early to mid-spring. Its smaller size makes it ideal for smaller gardens or as a focal point in borders. The flowers are intensely fragrant, attracting pollinators and adding seasonal beauty with minimal maintenance. Tinkerbell thrives in well-drained soil and full sun, making it a wonderful addition to New Mexico’s gardens for those seeking both color and fragrance in a manageable shrub.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "3-7",
          matureSize: "3' x 4'",
        },
      
      
      ],
      
    
      crapemyrtles: [
        {
          id: 21,
          name: "Dynamite Crape Myrtle",
          scientificName: "Lagerstroemia indica 'Dynamite'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/redish-crape.jpg",
          description: "The Dynamite Crape Myrtle is a standout for its vibrant red blooms, which create a bold splash of color throughout the summer. Known for its drought tolerance and heat resistance, this variety thrives in New Mexico’s dry climate with minimal care. Its smooth bark and glossy green leaves provide year-round interest, while its dense, upright growth habit makes it perfect for specimen planting or as a colorful hedge.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-9",
          matureSize: "15' x 12'",
        },
        {
          id: 22,
          name: "Natchez White Crape Myrtle",
          scientificName: "Lagerstroemia indica 'Natchez'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/white-crape.jpg",
          description: "With its rich pink flowers and compact size, the Arapaho Crape Myrtle is a perfect choice for smaller landscapes or container plantings. This variety blooms heavily in summer, attracting butterflies and hummingbirds, and has a strong resistance to powdery mildew, making it a low-maintenance option. Its glossy, dark green foliage transitions beautifully in the fall, and its smooth, exfoliating bark adds visual interest year-round.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-9",
          matureSize: "20' x 15'",
        },
        {
          id: 23,
          name: "Catawba Crape Myrtle",
          scientificName: "Lagerstroemia indica 'Catawba'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/catawba-crape.jpg",
          description: "The Catawba Crape Myrtle features vivid purple blooms that provide striking color in the summer months. Its uniform, rounded shape makes it an excellent addition to formal gardens or mixed shrub borders. This variety is heat-tolerant, drought-tolerant, and resistant to mildew, ensuring a vibrant display with minimal maintenance. Catawba’s smooth, attractive bark and rich fall foliage offer visual appeal throughout the year.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-9",
          matureSize: "15' x 10'",
        },
        {
          id: 24,
          name: "Pink Velour Crape Myrtle",
          scientificName: "Lagerstroemia indica 'Pink Velour'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/pink-crape",
          description: "With its deep pink flowers and striking dark foliage, Pink Velour Crape Myrtle offers a unique twist on traditional crape myrtles. This variety thrives in the heat, providing long-lasting blooms from summer through fall. Its contrasting foliage and smooth bark make it a standout in the landscape, perfect for adding both color and texture. Pink Velour is also known for its resistance to mildew and minimal maintenance needs, making it an excellent choice for low-care gardens.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-9",
          matureSize: "10' x 8'",
        },
       
      
      ],
      lavendars:
      [
        {
          id: 25,
          name: "English Lavendar",
          scientificName: "Lavandula angustifolia",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/lavender-4348354_1920.jpg",
          description: "English lavender thrives in New Mexico’s dry, sunny climate, making it a perfect choice for low-water gardens. This fragrant, drought-tolerant herb prefers well-draining soil and full sun, producing purple blooms that attract bees and butterflies. Once established, it requires minimal watering and resists deer and pests. Its aromatic flowers can be used for culinary, medicinal, or decorative purposes, adding both beauty and function to any garden.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/LowestWater.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "1.5' x 1.5'",
        },
        {
          id: 26,
          name: "Spanish Lavendar",
          scientificName: "Lavandula stoechas",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/spanish-lavender.jpg",
          description: "Spanish lavender is well-suited for New Mexico’s hot, dry climate, thriving in full sun and well-draining soil. Its distinctive purple flower spikes, topped with petal-like bracts, bloom from spring to summer, attracting pollinators. More heat-tolerant than English lavender, it requires minimal water once established and resists deer and pests. With its strong fragrance and unique blooms, Spanish lavender is a great choice for xeriscaping, borders, or container gardens.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "7-9",
          matureSize: "2' x 2'",
        },
        {
          id: 27,
          name: "Grosso Hybrid Lavender",
          scientificName: "Lavandula x intermedia ‘Grosso’",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/hybrid-lavender.jpg",
          description: "‘Grosso’ lavender is a hardy, drought-tolerant hybrid ideal for New Mexico gardens. Thriving in full sun and well-draining soil, it produces long-stemmed, highly fragrant purple flowers from summer to fall, attracting bees and butterflies. More heat and cold-tolerant than English lavender, ‘Grosso’ requires minimal watering once established. Its high oil content makes it popular for sachets, essential oils, and dried arrangements, adding both beauty and function to xeriscapes and herb gardens.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "3' x 3'",
        },
      ],
      
      roseofsharons: [
        {
          id: 28,
          name: "Aphrodite Rose of Sharon",
          scientificName: "Hibiscus syriacus 'Aphrodite'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/aphrodite-ROS.jpg",
          description: "Aphrodite Rose of Sharon features large, pink blooms with a darker red center, offering a bold pop of color from late summer to fall. Known for its disease resistance and compact, rounded form, this variety is ideal for smaller gardens or as an accent shrub. Aphrodite thrives in hot, dry climates and requires minimal care, making it a perfect choice for New Mexico gardeners looking for vibrant, low-maintenance blooms.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "8' x 6'",
        },
        {
          id: 29,
          name: "Red Heart Rose of Sharon",
          scientificName: "Hibiscus syriacus 'Red Heart'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/redheart-ROS.jpg",
          description: "Red Heart Rose of Sharon is known for its large, striking white flowers with a deep red center, creating a dramatic contrast. Its upright, dense habit makes it perfect for adding height and structure to gardens, while the flowers provide a late-summer burst of color. Red Heart is low-maintenance, drought-tolerant, and attracts butterflies, making it a great choice for pollinator-friendly landscaping in New Mexico.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "8' x 6'",
        },
        {
          id: 30,
          name: "Purple Pillar Rose of Sharon",
          scientificName: "Hibiscus syriacus 'Purple Pillar'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/purplepillar-ROS.jpg",
          description: "The Purple Pillar Rose of Sharon is a unique, columnar variety that grows upright, with striking purple flowers that bloom in late summer. Perfect for narrow spaces, this variety adds vertical interest and seasonal color to gardens. It’s a hardy shrub, well-suited for New Mexico’s dry conditions, and attracts pollinators with its showy blooms. Purple Pillar is low-maintenance, requiring little pruning and providing beauty with minimal effort.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "10' x 3'",
        },
   
      
      ],
      
      forsythias: [
        {
          id: 31,
          name: "Spring Glory Forsythia",
          scientificName: "Forsythia x intermedia 'Spring Glory'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/forsythia.jpg",
          description: "Spring Glory Forsythia is one of the earliest bloomers in the garden, featuring bright yellow flowers that burst forth in early spring, often before the leaves appear. This fast-growing shrub offers vibrant color and fills the garden with cheer after a long winter. It thrives in full sun and well-drained soil, providing a stunning early-season display with minimal maintenance. Its dense, upright growth habit makes it ideal for hedges or as a backdrop for spring flowering plants.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "8' x 8'",
        },
        {
          id: 32,
          name: "Medowlark Forsythia",
          scientificName: "Forsythia x intermedia 'Medowlark'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/forsythia-shrub.jpg",
          description: "Meadowlark Forsythia is known for its abundant yellow blooms that light up the garden in early spring, offering an eye-catching display of color. This variety is a bit more compact than others, making it a great choice for smaller gardens or as a low-maintenance hedge. It adapts well to various soil types and can tolerate New Mexico’s varying climate, providing early-season beauty with minimal water needs.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "6' x 6'",
        },
        {
          id: 33,
          name: "Magic Gold Forsythia",
          scientificName: "Forsythia x intermedia 'Magic Gold'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/forsythia2.jpg",
          description: "Magic Gold Forsythia offers a profusion of bright yellow flowers that appear in early spring, signaling the end of winter and the start of the growing season. Known for its strong, upright growth habit, this variety reaches a manageable size, making it perfect for hedges, borders, or accent plantings. Magic Gold is drought-tolerant once established and thrives in full sun, making it an excellent choice for New Mexico gardeners looking for vibrant, low-maintenance blooms.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "6' x 6'",
        },
        {
          id: 34,
          name: "Sunrise Forsythia",
          scientificName: "Forsythia x intermedia 'Sunrise'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/forsythia-4091197_1920.jpg",
          description: "Sunrise Forsythia is a striking variety that delivers a bright yellow floral display in early spring, followed by rich green foliage throughout the growing season. Its dense, upright habit makes it an excellent choice for creating hedges or as a specimen plant. Sunrise Forsythia is easy to grow, tolerates a variety of soil types, and requires little maintenance once established, making it ideal for New Mexico gardens that benefit from early-season color and minimal care.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "6' x 6'",
        },
      
      ],
      spireas: [
        {
          id: 35,
          name: "Blue Mist Spirea",
          scientificName: "Caryopteris × clandonensis ‘Blue Mist’",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/caryopteris-78768_1920.jpg",
          description: "Blue Mist Spirea is a fragrant, deciduous shrub that adds a pop of color to New Mexico gardens with its stunning blue-purple flowers in late summer to fall. Its silvery-gray foliage provides a beautiful contrast to the vibrant blooms. Growing to about 2-3 feet tall, it’s perfect for borders, containers, or as a low hedge. This drought-tolerant shrub thrives in full sun, and mulching helps retain moisture and keep roots protected.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "3' x 3'",
      },
        {
          id: 36,
          name: "Dark Knight Spirea",
          scientificName: "Caryopteris × clandonensis ‘Dark Knight’",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/caryopteris-2.jpg",
          description: "Dark Knight Spirea is a compact, hardy shrub that features deep blue, aromatic flowers against a backdrop of dark green foliage. This variety reaches about 2 feet tall and blooms in late summer to fall, attracting pollinators like bees and butterflies. It’s well-suited for dry climates, requiring minimal water once established. Mulching helps maintain soil moisture and provides root protection.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "3' x 3'",
      },
        {
          id: 37,
          name: "Sunshine Blue Spirea",
          scientificName: "Caryopteris × clandonensis ‘Sunshine Blue’",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/caryopoteris-3.jpg",
          description: "Sunshine Blue Caryopteris is a drought-tolerant shrub perfect for New Mexico gardens. Its golden-yellow foliage contrasts beautifully with deep blue flowers, attracting pollinators in late summer. Thriving in full sun and well-drained soil, it’s ideal for xeriscaping. Prune in early spring for a fresh, compact shape and vibrant new growth.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "3' x 3'",
      },

    
      ],
  
      xerics:[
        {
          id: 38,
          name: "Apache Plume",
          scientificName: "Fallugia paradoxa",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/apache-plume-1937218_1920.jpg",
          description: "Apache Plume is a striking shrub native to the Southwestern United States, valued for its elegant, feathery plumes that appear in late spring and early summer. These plumes are followed by small white flowers that provide a delicate contrast to its gray-green, aromatic foliage. Well-suited for New Mexico’s dry, rocky landscapes, Apache Plume thrives in full sun and well-drained soils, making it an excellent choice for xeriscape gardens. This drought-tolerant shrub attracts pollinators and offers year-round interest with its unique form and beautiful seed heads, which persist into the winter.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "4' x 4'",
        },
    
        {
          id: 40,
          name: "Fern Bush",
          scientificName: "Chamaebatiaria millefolium",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/fernbush.JPG",
          description: "Fern Bush is a native New Mexico shrub valued for its feathery, fern-like foliage and delicate, creamy-white flowers that bloom in late spring to early summer. Known for its ability to thrive in arid conditions, this shrub is highly drought-tolerant once established and grows best in well-drained soils with full sun. It’s perfect for xeriscaping, offering a graceful texture and providing wildlife habitat with its fragrant flowers that attract pollinators. With minimal maintenance, Fern Bush adds a soft, airy element to dry, desert landscapes.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "4-8",
          matureSize: "6' x 6'",
        },
        {
          id: 41,
          name: "Chamisa",
          scientificName: "Chrysothamnus nauseosus",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/needles-district-rabbitbrush-4009467_1920.jpg",
          description: "Chamisa, sometimes known as Rabbit Brush, is a native shrub that flourishes in New Mexico’s dry, rocky soils. Known for its bright yellow flowers that bloom in late summer to fall, it provides a cheerful burst of color when many other plants are past their prime. This drought-tolerant shrub thrives in full sun and well-drained soils, making it ideal for xeriscaping or low-maintenance gardens. Rabbit Brush attracts pollinators and offers a natural, wild look to desert landscapes, with its silvery-green foliage adding texture and contrast. Its resilience to harsh conditions makes it a perfect fit for arid environments.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "4-9",
          matureSize: "4' x 4'",
        },
        {
          id: 42,
          name: "Brakelights Red Yucca",
          scientificName: "Hesperaloe parviflora 'Brakelight'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Red-Yucca.jpg",
          description: "Brakelight Red Yucca is a striking, drought-tolerant succulent that thrives in New Mexico’s dry climate. Known for its vibrant red tubular flowers that bloom on tall spikes during the summer, it adds a bold pop of color to xeriscape gardens. The plant’s narrow, spiky foliage forms a clumping rosette, offering an architectural element to landscapes. Brakelight Red Yucca thrives in full sun and well-drained soils, requiring minimal water once established. Its low-maintenance nature and ability to attract hummingbirds make it an excellent choice for low-water gardens or desert landscapes.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-11",
          matureSize: "4' x 5'",
        },
      ],

      others: [
        {
          id: 43,
          name: "Flowering Quince",
          scientificName: "Chaenomeles speciosa",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/flowering-quince-4139038_1920.jpg",
          description: "Flowering quince is a hardy, drought-tolerant shrub that thrives in New Mexico’s arid climate. Blooming in late winter to early spring, its vibrant red, pink, or orange flowers attract pollinators. It prefers well-draining soil and full sun but adapts to various conditions. Once established, it requires little water, making it ideal for xeriscaping. In fall, it produces small, aromatic fruits that can be used for jellies or left for wildlife. With its spiny branches, flowering quince also works well as a natural barrier or hedge.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "5-9",
          matureSize: "6' x 6'",
        },
        {
          id: 44,
          name: "Spanish Broom",
          scientificName: "Spartium junceum",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/spanish-broom.jpg",
          description: "Spanish Broom is a sun-loving shrub that brings a touch of Mediterranean charm to New Mexico gardens with its bright yellow, pea-like flowers that bloom in late spring to early summer. Known for its long, slender stems and fine, narrow foliage, it adds a graceful, airy appearance to any landscape. Thriving in full sun and well-drained soils, Spanish Broom is drought-tolerant once established and ideal for xeriscape gardening. Its vibrant blooms attract pollinators, and its low-maintenance nature makes it an excellent choice for gardeners seeking a colorful, water-wise shrub.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "8-11",
          matureSize: "8' x 8'",
        },
        {
          id: 45,
          name: "Little Spark Spirea",
          scientificName: "Spiraea japonica ‘Little Spark’",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/spiraea-5285985_1920.jpg",
          description: "Little Spark Spirea is a small, compact shrub with striking pink flowers that bloom profusely in summer. The dark green foliage turns shades of red and orange in the fall, providing additional seasonal interest. Reaching only about 18-24 inches tall, it’s an excellent choice for smaller gardens, containers, or low hedges. It thrives in full sun and well-drained soil, with mulching helping to retain moisture and regulate temperature.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "3-8",
          matureSize: "1.5' x 2'",
      },
        {
        id: 46,
        name: "Mexican Bird of Paradise",
        scientificName: "Caesalpinia mexicana",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/flowering/bird-of-paradise-bush-3494940_1920.jpg",
        description: "The Mexican Bird of Paradise is an exotic, drought-tolerant plant known for its striking, bird-like flowers in shades of orange and yellow. It thrives in New Mexico’s sunny, dry climate and adds a dramatic flair to gardens with its upright, lush foliage. While it prefers full sun, it’s adaptable to other growing conditions if needed. Jericho recommends using this plant as a xeric tree or shrub to enhance the color in your xeriscaped sections of yard or garden.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
        hardinessZones: "7-10",
        matureSize: "10' x 10'",
        native: true
        },
        {
          id: 47,
          name: "Russian Sage",
          scientificName: "Perovskia atriplicifolia",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/russian-sage-6602342_1920.jpg",
          description: "Russian Sage is a tough, drought-tolerant perennial that thrives in New Mexico’s hot, dry climate. Its tall, airy spikes of lavender-blue flowers bloom from summer to fall, attracting bees and butterflies. With silvery, fragrant foliage, it adds texture and contrast to xeriscapes and low-water gardens. Thriving in full sun and well-drained soil, it’s a low-maintenance choice for adding long-lasting color to the landscape.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
          waterRequirement: "Low Water",
          hardinessZones: "4-9",
          matureSize: "4' x 3'",
        },
  
  
      ],
      
  
   
  
  
  };
  
  export default floweringshrubdata;
  
const xericbedding =
{
    flowers: [
      {
        id: 1,
        name: "Mexican Evening Primrose",
        scientificName: "Oenothera speciosa",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/mexican-evening-primrose.jpg",
        description: "Mexican Evening Primrose is a hardy, drought-tolerant perennial perfect for New Mexico gardens. Its pink, cup-shaped flowers bloom from spring to fall, attracting pollinators while thriving in full sun and well-draining soil. Spreading easily by rhizomes, it makes excellent ground cover but can become invasive if unchecked. Ideal for xeriscaping, it requires minimal water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-9",
        matureSize: `12" x 2'`,
        native: false
      },
      {
        id: 2,
        name: "Portulaca",
        scientificName: "Portulaca grandiflora",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/portulaca-2606950_1920.jpg",
        description: "Portulaca, or moss rose, is a heat-loving, drought-tolerant annual that thrives in New Mexico’s dry climate. With vibrant, rose-like blooms in shades of pink, red, orange, and yellow, it adds color to gardens from summer to fall. This low-growing succulent prefers full sun and well-draining soil, making it perfect for rock gardens, borders, or containers. Requiring little water, Portulaca is an excellent choice for xeriscaping and pollinator-friendly landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "10-11",
        matureSize: `6" x 12"`,
        native: false
      },

        {
            id: 3,
            name: "Thrift",
            scientificName: "Armeria maritima",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/thrift.jpg",
            description: "Thrift is a hardy, compact perennial ideal for New Mexico gardens. Known for its bright, spherical clusters of pink, red, or white flowers, it thrives in well-draining soil and full sun. This low-maintenance plant is drought-tolerant once established, making it a great addition to xeriscaping or coastal-inspired landscapes. With its mounding habit and tolerance for dry conditions, Thrift adds color and texture to rock gardens, borders, or containers.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: `8" x 8"`,
            native: false
          },
      
        {
            id: 4,
            name: "Speedwell",
            scientificName: "Veronica spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/speedwel.jpg",
            description: "Speedwell is a versatile perennial that flourishes in New Mexico’s sunny, dry conditions. Known for its spikes of blue, purple, or white flowers, it attracts pollinators and blooms from spring to early summer. Speedwell thrives in well-draining soil and full sun, making it an excellent choice for rock gardens or borders. Drought-tolerant once established, it’s a low-maintenance plant that adds vibrant color to xeriscapes while requiring minimal water.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `18" x 18"`,
            native: false
          },
        {
            id: 5,
            name: "Mexican Hat",
            scientificName: "Ratibida columnifera",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/flower-1378734_1920.jpg",
            description: "Mexican Hat is a hardy, drought-tolerant perennial native to the southwestern United States, making it a great choice for New Mexico gardens. Known for its striking, daisy-like flowers with red and yellow petals surrounding a conical, brown center, it blooms from summer to fall. Mexican Hat thrives in full sun and well-draining soil, requiring little water once established. Perfect for wildflower meadows, xeriscaping, or native plant gardens, it attracts pollinators while adding a pop of color and texture to dry landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: `22" x 2"`,
            native: false
          },
          {
            id: 6,
            name: "Crocus",
            scientificName: "Crocus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/crocus-8575610_1920.jpg",
            description: "Crocus is a vibrant, early-blooming perennial that adds a splash of color to New Mexico gardens in late winter or early spring. With its cup-shaped flowers in shades of purple, yellow, and white, Crocus thrives in well-draining soil and full to partial sun. This low-maintenance plant is perfect for rock gardens, borders, or naturalized areas and requires minimal water once established. Crocus is a great choice for gardeners looking to brighten up their landscapes with early-season blooms while benefiting from its drought tolerance.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `5" x 2"`,
            native: false
          },
  
        {
            id: 7,
            name: "Penstemon",
            scientificName: "Penstemon strictus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/penstemon.jpg",
            description: "Penstemon is a vibrant perennial well-suited to New Mexico’s climate. With tall spikes of tubular flowers in colors ranging from purple to red and pink, Penstemon blooms from spring to summer, attracting hummingbirds and pollinators. It thrives in full sun and well-draining soil, making it an excellent choice for xeriscaping and rock gardens. Drought-tolerant once established, Penstemon requires little water and adds bold color and texture to dry, sunny landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `24" x 30"`,
            native: false
          },

        {
            id: 8,
            name: "Rockcress",
            scientificName: "Arabis spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/golden-rock-cress-7490_1920.jpg",
            description: "Rockcress is a hardy, low-growing perennial perfect for New Mexico gardens. Known for its clusters of small, four-petaled white or pink flowers, Rockcress blooms in early spring, brightening up rock gardens, borders, and slopes. It thrives in full sun and well-draining soil, making it ideal for xeriscaping. Drought-tolerant once established, this low-maintenance plant adds color and texture to dry landscapes while requiring minimal water. Its compact size and ability to spread make it a great choice for ground cover.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `8" x 18"`,
            native: false
          },
        {
            id: 9,
            name: "Snow in Summer",
            scientificName: "Cerastium tomentosum",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/snow-in-summer.jpg",
            description: "Snow in Summer is a fast-growing perennial known for its silvery foliage and carpet of small, white flowers that bloom in late spring to early summer. Perfect for New Mexico’s dry climate, it thrives in full sun and well-draining soil, making it an excellent choice for xeriscaping or rock gardens. Snow in Summer is drought-tolerant once established and provides a low-maintenance ground cover that adds both texture and color to dry, sunny landscapes. Its ability to spread makes it ideal for filling in empty spaces in gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-7",
            matureSize: `8" x 18"`,
            native: false
          },
        {
            id: 10,
            name: "Creeping Phlox",
            scientificName: "Phlox subulata",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/creeping-phlox-in-the-ozarks-4123341_1920.jpg",
            description: "Creeping Phlox is a low-growing, colorful perennial that thrives in New Mexico’s dry, sunny conditions. Known for its vibrant blooms in shades of pink, purple, and white, Creeping Phlox flowers in spring, adding a burst of color to rock gardens, borders, and slopes. It prefers well-draining soil and full sun, making it an excellent choice for xeriscaping. Drought-tolerant once established, it requires minimal water and works well as a ground cover, spreading quickly to fill in spaces while attracting pollinators.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: `6" x 18"`,
            native: false
          },
        {
            id: 11,
            name: "California Poppy",
            scientificName: "Eschscholzia californica",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/california-poppy.jpg",
            description: "California Poppy is a bright, cheerful annual that thrives in New Mexico’s dry, sunny climate. Known for its vibrant orange, yellow, or red flowers, it blooms from spring to early summer, adding a splash of color to gardens. This drought-tolerant plant prefers well-draining soil and full sun, making it ideal for xeriscaping or wildflower meadows. California Poppy requires minimal water once established and attracts pollinators like bees and butterflies, making it a great choice for low-maintenance, water-wise gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "8-11",
            matureSize: `8" x 8"`,
            native: false
          },
        {
            id: 12,
            name: "Desert Zinnia",
            scientificName: "Zinnia acerosa",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/DesertZinnia_(50619381807).jpg",
            description: "Desert Zinnia is a drought-tolerant perennial native to the southwestern U.S., making it a perfect addition to New Mexico gardens. With its bright, daisy-like flowers in shades of yellow, orange, and pink, Desert Zinnia blooms from spring to fall, attracting pollinators such as bees and butterflies. It thrives in full sun and well-draining soil, requiring minimal water once established. This hardy plant is ideal for xeriscaping, rock gardens, and dry landscapes, adding vibrant color and texture to arid environments.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "6-11",
            matureSize: `12" x 12"`,
            native: false
          },
   
        {
            id: 13,
            name: "Oriental Poppy",
            scientificName: "Papaver orientale",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/oriental-poppy.jpg",
            description: "Oriental Poppy is a striking perennial that thrives in New Mexico's sunny, dry climate. Known for its large, vibrant blooms in shades of red, orange, and pink, it flowers in late spring to early summer, creating a bold statement in the garden. Preferring well-draining soil and full sun, Oriental Poppy is drought-tolerant once established. While it requires minimal water, it benefits from occasional deep watering in hotter months. This plant is ideal for adding a dramatic touch to xeriscapes, borders, or wildflower gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-7",
            matureSize: `30" x 20"`,
            native: false
          },
        {
            id: 14,
            name: "Day Lillies",
            scientificName: "Hemerocallis spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/daylily-5286921_1920.jpg",
            description: "Daylilies are hardy perennials that thrive in New Mexico’s sunny, dry conditions. Known for their vibrant, trumpet-shaped flowers in a variety of colors, Daylilies bloom profusely in late spring and summer, with each flower lasting just one day but replaced by others throughout the blooming season. These low-maintenance plants prefer well-draining soil and full sun, making them an excellent choice for xeriscaping and garden borders. Drought-tolerant once established, Daylilies require minimal water and provide long-lasting color to dry, sunny landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: `12" x 12"`,
            native: false
          },
        {
            id: 15,
            name: "Hardy African Daisy",
            scientificName: "Arctotis spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/hardy-african-daisy.jpg",
            description: "Hardy African Daisy is a vibrant perennial that thrives in New Mexico’s hot, dry climate. Known for its large, daisy-like flowers in shades of white, orange, and purple, this plant blooms from spring to fall, adding bright color to gardens. It prefers full sun and well-draining soil, making it ideal for xeriscaping or rock gardens. Once established, Hardy African Daisy is drought-tolerant and requires minimal water, making it a perfect low-maintenance choice for dry, sunny landscapes while attracting pollinators.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "9-11",
            matureSize: `10" x 18"`,
            native: false
          },
        {
            id: 16,
            name: "Firewheel",
            scientificName: "Gaillardia pulchella",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/indian-blanket-9063340_1920.jpg",
            description: "Firewheel, also known as blanket flower, is a hardy, drought-tolerant perennial that thrives in New Mexico’s arid climate. With its striking, daisy-like flowers in vibrant red, orange, and yellow, Firewheel blooms from spring to fall, adding a burst of color to gardens. It prefers full sun and well-draining soil, making it ideal for xeriscaping, wildflower meadows, and rock gardens. Once established, Firewheel is low-maintenance and requires minimal water, while also attracting pollinators like bees and butterflies.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "2-11",
            matureSize: `18" x 8"`,
            native: false
          },
        {
            id: 17,
            name: "Aster",
            scientificName: "Dieteria canescens",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/aster.jpg",
            description: "Aster is a versatile perennial that adds late-season color to New Mexico gardens. Known for its star-shaped flowers in shades of purple, blue, pink, and white, Asters bloom in late summer to fall, attracting pollinators like bees and butterflies. They thrive in full sun and well-draining soil, making them perfect for borders, wildflower meadows, and xeriscaping. Once established, Asters are drought-tolerant and require minimal water, providing long-lasting color and texture to dry, sunny landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-9",
            matureSize: `12" x 18"`,
            native: false
          },
        {
            id: 18,
            name: "Chrysanthemum",
            scientificName: "Chrysanthemum spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/chrysanthemums-3780098_1920.jpg",
            description: "Chrysanthemum is a popular perennial that brings vibrant fall color to New Mexico gardens. With its wide range of flower shapes and colors, including yellow, red, pink, and purple, Chrysanthemums bloom in late summer to fall, adding a cheerful display to landscapes. They thrive in full sun and well-draining soil, making them ideal for garden beds, borders, and containers. Once established, Chrysanthemums are relatively drought-tolerant, requiring minimal water, and attract pollinators such as bees and butterflies, making them a great addition to xeriscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: "2' x 3'",
            native: false
          },
     
        {
            id: 19,
            name: "Columbine",
            scientificName: "Aquilegia spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/columbine-7246650_1920.jpg",
            description: "Columbine is a delicate, shade-loving perennial that adds charm to New Mexico gardens. Known for its unique, spurred flowers in shades of red, yellow, purple, and blue, Columbine blooms in spring to early summer, attracting hummingbirds and pollinators. It thrives in well-draining soil and partial to full shade, making it perfect for woodland gardens or shaded borders. While it prefers moderate moisture, Columbine is drought-tolerant once established and adds a beautiful, textured touch to gardens with its graceful, fern-like foliage.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `24" x 12"`,
            native: false
          },
          {
            id: 20,
            name: "Coreopsis",
            scientificName: "Coreopsis spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/coreopsis-2665188_1920.jpg",
            description: "Coreopsis is a hardy, drought-tolerant perennial that thrives in New Mexico's sunny, dry climate. Known for its bright, daisy-like flowers in shades of yellow, pink, and red, Coreopsis blooms from spring to fall, providing long-lasting color to gardens. It prefers well-draining soil and full sun, making it ideal for xeriscaping, wildflower meadows, and borders. Once established, Coreopsis requires minimal water, making it a low-maintenance choice that attracts pollinators while brightening up dry landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-9",
            matureSize: `6" x 12"`,
            native: false
          },
          {
            id: 36,
            name: "Golden Yarrow",
            scientificName: "Eriophyllum confertiflorum",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/yarrow.jpg",
            description: "Golden yarrow is a hardy, drought-tolerant perennial ideal for New Mexico gardens. This low-maintenance plant features clusters of bright yellow flowers that bloom from spring to summer, attracting bees and butterflies. Thriving in full sun and well-draining soil, golden yarrow is perfect for xeriscaping, as it requires minimal water once established. Its soft, silvery-green foliage adds texture and contrast to gardens, making it an excellent choice for low-water landscapes and native plant gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: "2' x 2'",
            native: false
          },
          {
            id: 37,
            name: "Red Hot Poker",
            scientificName: "Kniphofia uvaria",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/red-hot-poker.jpg",
            description: "Red hot poker is a striking, drought-tolerant perennial that thrives in New Mexico’s sunny, dry climate. Known for its tall, tubular flowers that range from bright red to orange, it adds a bold pop of color to gardens from summer to fall. Red hot poker prefers full sun and well-draining soil, requiring minimal water once established. This plant attracts hummingbirds and butterflies, making it a great choice for pollinator gardens and xeriscapes, adding both beauty and resilience to low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-9",
            matureSize: "4' x 2'",
            native: false
          },
          {
            id: 38,
            name: "Common Hyssop",
            scientificName: "Hyssopus officinalis",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/hyssop-225065_1920.jpg",
            description: "Common hyssop is a hardy, aromatic herb well-suited for New Mexico’s dry climate. With its upright stems and spikes of purple-blue flowers, it attracts pollinators like bees and butterflies throughout the summer. Thriving in full sun and well-draining soil, common hyssop requires minimal water once established. This drought-tolerant herb is perfect for xeriscaping and herb gardens, and its leaves are often used in cooking, teas, or medicinally. Its strong fragrance also makes it a great addition to fragrant, low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-9",
            matureSize: "1.5' x 1'",
            native: false
          },
          {
            id: 39,
            name: "Mexican Hyssop",
            scientificName: "Agastache mexicana",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/Agastache_rupestre.jpg",
            description: "Mexican hyssop is a vibrant, drought-tolerant perennial that thrives in New Mexico’s dry climate. Known for its clusters of tubular, purple to orange flowers, it attracts hummingbirds and butterflies throughout the summer. This herbaceous plant prefers full sun and well-draining soil, requiring minimal water once established. With its aromatic foliage and ability to tolerate heat, Mexican hyssop is an excellent choice for xeriscaping, pollinator gardens, or adding color and texture to low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-10",
            matureSize: "4' x 2'",
          },
    ],
    groundcovers: [
        {
            id: 21,
            name: "Poppy Mallow",
            scientificName: "Callirhoe involucrata",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/purple-poppy-mallow.jpg",
            description: "Poppy Mallow is a striking perennial native to the southwestern U.S., perfect for New Mexico gardens. Known for its vibrant, cup-shaped pink or purple flowers, Poppy Mallow blooms profusely in late spring and summer, attracting pollinators like bees and butterflies. It thrives in well-draining soil and full sun, making it an ideal choice for xeriscaping or rock gardens. Drought-tolerant once established, Poppy Mallow requires minimal water and adds a bold, colorful touch to dry, sunny landscapes with its spreading habit.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: `8" x 36"`,
            native: false
          },
          {
            id: 22,
            name: "Hardy Plumbago",
            scientificName: "Ceratostigma plumbaginoides",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/hardy%20plumbago.jpg",
            description: "Hardy Plumbago is a resilient perennial that adds vibrant color to New Mexico gardens. With its striking blue flowers that bloom from late summer to fall, Hardy Plumbago thrives in full sun to partial shade and well-draining soil. This drought-tolerant plant requires minimal water once established, making it an excellent choice for xeriscaping. Its spreading habit and attractive foliage, which turns red in fall, add texture and interest to dry, sunny landscapes or garden borders. Hardy Plumbago is a low-maintenance option for gardeners seeking reliable color in arid conditions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-9",
            matureSize: `8" x 12"`,
            native: false
          },
          {
            id: 23,
            name: "Creeping Mahonia",
            scientificName: "Mahonia repens",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/creeping-mahonia.jpg",
            description: "Creeping Mahonia is a low-growing, evergreen shrub that thrives in New Mexico’s dry and rocky landscapes. Known for its glossy, holly-like leaves and clusters of bright yellow flowers in early spring, Creeping Mahonia adds year-round interest to gardens. It prefers partial to full shade and well-draining, slightly acidic soil, making it ideal for woodland gardens or shaded borders. Drought-tolerant once established, it requires minimal water and offers attractive foliage throughout the year, with its leaves turning a reddish hue in winter. This hardy plant is perfect for xeriscaping and adding texture to shady areas.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-8",
            matureSize: `12" x 18"`,
            native: false
          },
          {
            id: 24,
            name: "Wild Four 'o Clock",
            scientificName: "Mirabilis multiflora",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/wild%20fouro-clock.jpg",
            description: "Wild Four O'Clock is a hardy, drought-tolerant perennial native to the southwestern United States, making it well-suited for New Mexico gardens. Known for its fragrant, trumpet-shaped flowers that bloom in late afternoon or evening, Wild Four O'Clock features vibrant colors like pink, purple, and yellow. It thrives in full sun and well-draining soil, requiring minimal water once established. This plant is ideal for xeriscaping, rock gardens, or wildflower meadows, offering long-lasting blooms that attract pollinators while adding a touch of color and fragrance to dry landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-10",
            matureSize: `18" x 18"`,
            native: false
          },
          {
            id: 26,
            name: "Yerba Mansa",
            scientificName: "Anemopsis californica",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/anemopsis-californica-2699391_1920.jpg",
            description: "Yerba Mansa is a native perennial well-suited to New Mexico’s arid landscapes, especially in areas with occasional moisture. Known for its aromatic, white, spade-shaped flowers and thick, green foliage, Yerba Mansa thrives in wetland areas, along stream banks, or in boggy conditions, though it can tolerate drought once established. It prefers full sun to partial shade and well-draining soil, making it a great choice for xeriscaping with occasional watering. Yerba Mansa attracts pollinators and provides texture and visual interest with its distinctive, aromatic leaves and unique flower structure.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-10",
            matureSize: `8" x 24"`,
            native: false
          },

          {
            id: 27,
            name: "Prarie Zinnia",
            scientificName: "Zinnia grandiflora",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/prarie-zinnia.jpg",
            description: "Prairie Zinnia is a drought-tolerant perennial native to the southwestern U.S., perfect for New Mexico’s dry climate. Known for its bright, daisy-like flowers in shades of yellow, orange, and red, Prairie Zinnia blooms from spring to fall, attracting pollinators such as bees and butterflies. It thrives in full sun and well-draining soil, making it ideal for xeriscaping, wildflower meadows, and garden borders. Once established, Prairie Zinnia requires minimal water and provides vibrant, long-lasting color to dry, sunny landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-8",
            matureSize: `8" x 18"`,
            native: false
          },
          {
            id: 28,
            name: "Damianita",
            scientificName: "Chrysactinia mexicana",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/Chrysactinia_mexicana_-_Flickr_-_aspidoscelis.jpg",
            description: "Damianita is a fragrant, drought-tolerant shrub native to the southwestern U.S. and northern Mexico, making it an excellent choice for New Mexico gardens. Known for its aromatic, yellow daisy-like flowers and aromatic, leathery leaves, Damianita blooms from spring to fall, attracting pollinators like bees and butterflies. It thrives in full sun and well-draining, rocky soil, making it ideal for xeriscaping and rock gardens. Once established, Damianita requires minimal water and offers a low-maintenance, colorful addition to dry, sunny landscapes with its strong, pleasant scent.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-11",
            matureSize: `18" x 18"`,
            native: false
          },
          {
            id: 29,
            name: "Silver Carpet",
            scientificName: "Dymondia margaretae",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/silver-carpet.JPG",
            description: "Silver Carpet is a low-growing, drought-tolerant ground cover that thrives in New Mexico's dry, sunny climate. Known for its silvery-gray foliage and yellow, daisy-like flowers, Silver Carpet creates a soft, textured mat that spreads quickly, making it an excellent choice for xeriscaping and rock gardens. It prefers well-draining soil and full sun, requiring minimal water once established. This hardy plant adds both color and visual interest to dry, sunny landscapes, making it an ideal low-maintenance option for filling in spaces or as a ground cover in arid regions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "9-11",
            matureSize: `2" x 18"`,
            native: false
          },
    ],

    succelents: [
    {
        id: 30,
        name: "Ice Plant",
        scientificName: "Delosperma spp.",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/ice-plant.jpg",
        description: "Ice Plant is a vibrant, drought-tolerant ground cover perfect for New Mexico’s dry, sunny conditions. Known for its striking, daisy-like flowers in shades of pink, purple, and yellow, Ice Plant blooms profusely in summer, attracting pollinators like bees and butterflies. It thrives in well-draining soil and full sun, making it ideal for xeriscaping, rock gardens, or as a low-maintenance ground cover. Once established, Ice Plant is extremely drought-tolerant and requires minimal water, offering bright, colorful flowers and a thick, mat-forming habit that helps conserve moisture in the soil.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-9",
        matureSize: `4" x 18"`,
        native: false
    },
    {
        id: 31,
        name: "Hens and Chicks",
        scientificName: "Sempervivum spp.",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/hens-and-chics.jpg",
        description: "Hens and Chicks is a hardy, drought-tolerant succulent that thrives in New Mexico’s dry, sunny climate. Known for its rosettes of thick, fleshy leaves, the 'hen' forms the central rosette, while 'chicks' are smaller offshoots that surround it. These plants are available in a range of colors, from green to red and purple, and can bloom with star-like flowers in the summer. Hens and Chicks prefer well-draining soil and full sun, making them perfect for xeriscaping, rock gardens, or containers. Once established, they require minimal water, making them an excellent low-maintenance choice for dry landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-8",
        matureSize: `3" x 12"`,
        native: false
    },
    {
        id: 32,
        name: "Sedum",
        scientificName: "Sedum spp.",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/sedum.jpg",
        description: "Sedum, also known as 'stonecrop,' is a versatile, drought-tolerant succulent that thrives in New Mexico's arid climate. Known for its thick, fleshy leaves and clusters of star-shaped flowers in shades of yellow, pink, and red, Sedum is ideal for xeriscaping, rock gardens, or containers. It prefers well-draining soil and full sun, making it perfect for dry, sunny landscapes. Once established, Sedum is highly drought-tolerant and requires minimal water. Its low-maintenance nature, along with its ability to attract pollinators like bees and butterflies, makes it an excellent addition to any water-wise garden.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
        hardinessZones: "3-9",
        matureSize: `12" x 18"`,
        native: false
    },
  

    {
        id: 35,
        name: "Spurges",
        scientificName: "Euphorbia spp.",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/bedding/spurges.jpg",
        description: "Spurges are a diverse group of drought-tolerant plants that thrive in New Mexico’s dry, sunny conditions. Known for their unique, often spiny stems and small, colorful bracts that resemble flowers, Spurges come in a variety of shapes and sizes, from low-growing ground covers to taller shrubs. They prefer well-draining soil and full sun, making them ideal for xeriscaping, rock gardens, or borders. Once established, Spurges require minimal water and are highly tolerant of heat and drought. Their striking foliage and resilience make them an excellent addition to dry landscapes in need of low-maintenance, water-wise plants.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-11",
        matureSize: `12" x 18"`,
        native: false
    },
    ],

}
export default xericbedding
const coniferousshrubs = {
    pines: [
   {
        id: 1,
        name: "Mugo Pine",
        scientificName: "Pinus mugo",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/mugo-pine.JPG",
        description: "Mugo Pine is a compact, evergreen conifer that offers a dense, bushy growth habit, making it an excellent choice for low hedges, rock gardens, or as a specimen plant. Its dark green needles and rounded form provide year-round interest, with the added bonus of small, cylindrical cones that appear in late summer. Mugo Pine typically grows to 3-5 feet in height, though some varieties can reach up to 10 feet. It thrives in well-drained soil and full sun, is highly drought-tolerant once established, and requires minimal maintenance. Mulching around the base helps retain moisture and protect the roots in New Mexico’s dry conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-7",
        matureSize: "6' x 8'",
      },
   {
        id: 2,
        name: "Limber Pine",
        scientificName: "Pinus flexilis",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/limber-pine.jpg",
        description: "Limber Pine, when grown as a shrub, maintains a more compact, dense form, typically reaching around 4 to 8 feet in height. It retains its signature long, soft needles and a flexible, open structure, making it a unique addition to smaller landscapes. As a shrub, it is ideal for use in low hedges, rock gardens, or as an accent plant in dry, sunny areas. Limber Pine thrives in well-drained soil and full sun, and it is highly drought-tolerant once established. Mulching can help retain moisture and protect the roots in New Mexico’s arid conditions. This adaptable shrub adds a distinct, textured element to xeriscape gardens.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-7",
        matureSize: "20' x 20'",
      },
      {
        id: 3,
        name: "Pinyon Pine",
        scientificName: "Pinus edulis",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Pinon.jpg",
        description: "Pinyon pines are a staple of New Mexico’s landscape and culture, prized for their rich, buttery piñon nuts harvested for generations. Native to the Four Corners region, these hardy evergreens thrive in the high desert with minimal water, making them ideal for xeriscaping. Their rounded canopies and dense foliage provide year-round greenery, shade, and privacy, while deep roots help stabilize soil. From Farmington to Las Cruces, the pinyon pine remains a cherished symbol of the state’s rugged beauty and resilience.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-8",
        matureSize: "20' x 25'",
        native: true
      },
   {
        id: 4,
        name: "Dwarf Mountain Pine",
        scientificName: "Pinus mugo 'Pumilio'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/mugoPine.jpg",
        description: "Dwarf Mountain Pine is a compact, low-growing evergreen shrub that typically reaches 2 to 4 feet in height, making it an ideal choice for smaller gardens, rock gardens, or as a border plant. With its dense, dark green needles and rounded shape, it provides year-round interest and texture. This variety of mountain pine is highly drought-tolerant once established, thriving in well-drained soil and full sun. It's perfect for xeriscaping and requires minimal maintenance, with occasional pruning to maintain its shape. Mulching helps retain moisture and protect the roots in New Mexico’s dry conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-7",
        matureSize: "4' x 4'",
      },

    ],
    junipers: [
    {
        id: 5,
        name: "Blue Rug Juniper",
        scientificName: "Juniperus horizontalis 'Wiltonii'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/Juniperus_horizontalis_02.JPG",
        description: "Blue Rug Juniper is a low-growing, spreading evergreen shrub that forms a dense, carpet-like groundcover. Its striking blue-green foliage provides excellent year-round color and texture, with the leaves turning slightly purple in winter. Typically growing only 6-12 inches in height, it spreads up to 4-6 feet wide, making it perfect for erosion control, slopes, or as a groundcover in xeriscape gardens. Blue Rug Juniper thrives in full sun and well-drained soil, and is highly drought-tolerant once established. Its minimal maintenance requirements make it an excellent choice for New Mexico’s dry climate, and mulching helps retain moisture and protect the roots.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-9",
        matureSize: "6' x 6'",
    },
    {
        id: 6,
        name: "Creeping Blue Juniper",
        scientificName: "Juniperus horizontalis",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/creeping-blue-juniper.jpg",
        description: "Creeping Blue Juniper is a hardy, low-growing evergreen shrub that forms a dense mat of blue-green foliage, ideal for ground cover or erosion control. Its vibrant color shifts to a purplish hue in the winter, adding visual interest year-round. Typically growing to only 6-12 inches tall, it spreads up to 3-5 feet wide, making it perfect for use along slopes, as a border, or in rock gardens. Creeping Blue Juniper thrives in full sun and well-drained soil, and once established, it is highly drought-tolerant, making it an excellent choice for New Mexico’s dry, arid climate. Mulching around its base helps retain moisture and protect the roots.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-9",
        matureSize: "1' x 6'",
    },
    {
        id: 7,
        name: "Parson's Juniper",
        scientificName: "Juniperus parsonii",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/parson's%20juniper.jpg",
        description: "Parson's Juniper is a compact, low-growing evergreen shrub known for its dense, dark green foliage. This juniper variety typically grows to about 2-3 feet in height and spreads 4-6 feet wide, making it a great option for ground cover, borders, or small hedges. It has a graceful, spreading habit and is highly adaptable to various soil types, thriving in full sun and well-drained soil. Once established, Parson's Juniper is drought-tolerant, making it an excellent choice for xeriscaping and dry landscapes in New Mexico. Mulching helps retain moisture and provides protection to its roots in arid conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-8",
        matureSize: "10' x 10'",
    },
    {
        id: 8,
        name: "Dwarf Japanese Garden Juniper",
        scientificName: "Juniperus procumbens 'Nana'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/Dwarf-japanese-juniper.jpg",
        description: "Dwarf Japanese Garden Juniper is a slow-growing, compact evergreen shrub with a low, spreading form. Typically reaching just 1-2 feet in height and spreading up to 3-4 feet wide, it is ideal for use as ground cover, in rock gardens, or as a low hedge. Its dense, dark green foliage provides year-round texture and interest, and it has a slightly cascading habit. This juniper thrives in full sun and well-drained soil, with excellent drought tolerance once established, making it a great choice for New Mexico's dry climate. Mulching around its base helps retain moisture and protects the roots from extreme temperature fluctuations.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-8",
        matureSize: "1' x 3'",
    },



    ],
    spruces: [
    {
        id: 9,
        name: "Dwarf Alberta Spruce",
        scientificName: "Picea glauca 'Conica'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/dwarf-albertaSpruce.jpg",
        description: "Dwarf Alberta Spruce is a slow-growing, compact evergreen conifer that forms a dense, pyramidal shape. Typically growing to 3-5 feet tall and 2-4 feet wide, this shrub makes an excellent choice for smaller landscapes, accent plantings, or as a formal hedge. Its bright green needles provide year-round color and texture, with a tidy, symmetrical appearance. Dwarf Alberta Spruce thrives in well-drained soil and full sun to partial shade, with moderate drought tolerance once established. Mulching around its base helps retain moisture and protect its roots, making it a perfect addition to New Mexico's dry conditions. Regular pruning can help maintain its neat, conical form.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '2-8',
        matureSize: "6' x 4'",
    },
    {
        id: 10,
        name: "Baby Blue Spruce",
        scientificName: "Picea pungens 'Baby Blue'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/baby-blue-spruce.jpg",
        description: "Baby Blue Spruce is a compact, slow-growing evergreen conifer with striking silvery-blue needles that provide year-round color. Typically reaching 3-4 feet in height and 2-3 feet in width, it has a neat, pyramidal shape, making it ideal for small gardens, accent plantings, or as a specimen tree. Baby Blue Spruce thrives in well-drained soil and full sun, with excellent drought tolerance once established. It’s perfect for xeriscaping in New Mexico’s dry climate, where it adds vibrant color and texture. Mulching around the base helps retain moisture and protects the roots from temperature extremes, ensuring healthy growth in arid conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "3-9",
        matureSize: "10' x 6'",
    },
    {
        id: 11,
        name: "Norway Spruce",
        scientificName: "Picea abies 'Acrocona'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/Picea_abies_'Acrocona'.JPG",
        description: "Nest Spruce is a compact, low-growing evergreen shrub that forms a unique, rounded shape resembling a bird's nest. Typically growing 2-3 feet tall and spreading up to 3-5 feet wide, this slow-growing spruce adds a distinct texture to gardens or rockeries. Its dense, dark green needles create a soft, cushion-like appearance that maintains its form year-round. Nest Spruce thrives in well-drained soil and full sun to partial shade, with moderate drought tolerance once established. It is an excellent choice for New Mexico’s dry climate, adding both visual interest and a touch of whimsy to xeriscaping or smaller garden spaces.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "3-7",
        matureSize: "30' x 20'",
    },

    ],
    arborvitaes: [
    {
        id: 12,
        name: "Tater Tot Thuja",
        scientificName: "Thuja occidentalis 'Tater Tot'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/thuja-49630_1920.jpg",
        description: "Tater Tot Thuja is a compact, globe-shaped evergreen shrub that typically grows to about 2-3 feet in height and width, making it a great choice for small gardens, borders, or as a low hedge. Its dense, soft green foliage maintains its vibrant color year-round and retains a tidy, rounded shape with little maintenance. Tater Tot Thuja thrives in well-drained soil and full sun to partial shade, and it’s relatively drought-tolerant once established. This charming shrub is an excellent option for xeriscaping in New Mexico, where it adds structure and greenery to dry landscapes. Mulching around its base helps retain moisture and protect the roots.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '3-7',
        matureSize: "3' x 3'",
    },
    {
        id: 13,
        name: "Golden Globe Arborvitae",
        scientificName: "Thuja occidentalis 'Golden Globe'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/thuja-3847882_1920.jpg",
        description: "Golden Globe Arborvitae is a compact, spherical evergreen shrub that grows to about 2-3 feet in height and width. Its vibrant golden-yellow foliage creates a striking contrast in landscapes, providing year-round color and texture. This low-maintenance shrub works well in borders, containers, or as an accent plant in small gardens. Golden Globe Arborvitae thrives in well-drained soil and full sun, where its color is most vibrant, and is moderately drought-tolerant once established. It is an excellent choice for New Mexico’s dry climate, offering both beauty and durability. Mulching around its base helps retain moisture and protect its roots from temperature fluctuations.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '3-7',
        matureSize: "4' x 4'",
    },
    {
        id: 14,
        name: "Little Giant Arborvitae",
        scientificName: "Thuja occidentalis 'Little Giant'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/Thuja_occidentalis_(34290271234).jpg",
        description: "Little Giant Arborvitae is a compact, rounded evergreen shrub that typically grows to 3-4 feet in height and width, making it an excellent choice for small gardens, borders, or containers. Its dense, rich green foliage provides year-round interest and maintains its rounded shape with minimal pruning. This arborvitae thrives in full sun to partial shade and well-drained soil, with moderate drought tolerance once established, making it ideal for New Mexico's dry conditions. Mulching around the base helps retain moisture and protect the roots.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '3-7',
        matureSize: "5' x 5'",
    },
  
    
    ],
    hollies: [
    {
        id: 15,
        name: "Desert Holly",
        scientificName: "Ilex aquifolium 'Desert Holly'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/ilex-aquifolium.jpg",
        description: "Desert Holly is a tough, drought-tolerant evergreen shrub well-suited for xeriscaping in arid climates like New Mexico. It typically grows to about 4-6 feet in height and width, with spiny, glossy green leaves and bright red berries in the fall and winter, adding vibrant color to dry landscapes. Desert Holly thrives in full sun and well-drained soil, offering excellent drought resistance once established. Its low-maintenance nature and ability to withstand harsh conditions make it a great choice for New Mexico gardens.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "7-9",
        matureSize: "4' x 4'",
    },
    {
        id: 16,
        name: "American Holly",
        scientificName: "Ilex opaca",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/american-holly.jpg",
        description: "American Holly is a native evergreen shrub or small tree, typically growing 15-30 feet tall, with a pyramidal shape and glossy, spiny dark green leaves. It produces bright red berries in the winter, attracting wildlife. While it prefers acidic, moist soil and partial shade, it can tolerate full sun if kept well-watered, and it’s well-suited for New Mexico’s dry climates with some attention to irrigation. Mulching around the base helps protect the roots and conserve moisture in arid conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "15' x 15'",
    },
    {
        id: 17,
        name: "Yaupon Holly",
        scientificName: "Ilex vomitoria",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/evergreen/yaupon-1962797_1920.jpg",
        description: "Yaupon Holly is a versatile evergreen shrub or small tree that can grow from 6-20 feet in height, making it suitable for a variety of landscaping uses. It features small, glossy green leaves and produces bright red berries that attract birds. Yaupon Holly thrives in full sun and well-drained soil, offering excellent drought tolerance once established. It’s perfect for New Mexico’s dry conditions and can be used as a hedge, screen, or specimen plant. Mulching helps retain moisture and protect the roots in hot, dry climates.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "7-9",
        matureSize: "8' x 8'",
    },

    ],


}
export default coniferousshrubs;
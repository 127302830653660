





const coniferdata = {
  pines: [
    {
      id: 1,
      name: "Pinyon Pine",
      scientificName: "Pinus edulis",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Pinon.jpg",
      description: "Pinyon pines are a staple of New Mexico’s landscape and culture, prized for their rich, buttery piñon nuts harvested for generations. Native to the Four Corners region, these hardy evergreens thrive in the high desert with minimal water, making them ideal for xeriscaping. Their rounded canopies and dense foliage provide year-round greenery, shade, and privacy, while deep roots help stabilize soil. From Farmington to Las Cruces, the pinyon pine remains a cherished symbol of the state’s rugged beauty and resilience.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-8",
      matureSize: "20' x 25'",
      native: true
    },
    {
      id: 2,
      name: "Austrian Pine",
      scientificName: "Pinus nigra",
      image: "https://jfschmidt.com/wp-content/uploads/2023/03/pinus-austrian-web-01.jpg",
      description: "Austrian pines are hardy evergreens well-suited to Albuquerque, growing 40 to 60 feet tall and providing excellent windbreaks, shade, and privacy. Their long, dark green needles form a dense canopy that withstands harsh sun and urban conditions. Drought-tolerant once established, they thrive in well-drained soils, making them a low-maintenance choice for adding greenery and structure to the high desert landscape.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "3-8",
      matureSize: "55' x 35'",
      native: false
    },
    {
      id: 3,
      name: "Afghan Pine",
      scientificName: "Pinus eldarica",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/pine-tree-6902428_1920.jpg",
      description: "Afghan pines are fast-growing evergreens that thrive in Albuquerque’s arid climate, reaching 30 to 60 feet tall. Native to the Middle East and Central Asia, they feature long, slender needles and a pyramidal shape, making them a popular choice for windbreaks, shade, and landscaping. Highly drought-tolerant once established, Afghan pines adapt well to poor soils and intense sun, requiring minimal maintenance.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "6-10",
      matureSize: "55' x 25'",
      native: false
    },
    // {
    //   id: 3,
    //   name: "Stone Pine",
    //   scientificName: "Pinus pinea",
    //   image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/stonePine.jpg",
    //   description: "Stone pines, also known as Italian stone pines, are distinctive evergreens valued for their broad, umbrella-like canopies and edible pine nuts. Native to the Mediterranean, they adapt well to Albuquerque’s climate. Their dense rounded crowns provide excellent shade, while their deep roots help stabilize soil. With their unique shape and low-maintenance needs, stone pines make a striking and practical addition to high desert landscapes.",
    //   sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
    //   sunRequirement: "Full Sun",
    //   waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/medHiWater.png",
    //   waterRequirement: "Moderate Water",
    //   hardinessZones: [4, 5, 6, 7],
    //   matureSize: "15' x 30' tall",
    // },
  ],

  junipers: [
    {
      id: 4,
      name: "Wichita Blue Juniper",
      scientificName: "Juniperus scopulorum 'Wichita Blue'",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/blueJuniper.jpg",
      description: "Wichita Blue junipers are striking evergreens known for their silvery-blue foliage and upright, pyramidal shape. They are an excellent choice for windbreaks, privacy screens, or ornamental accents in Albuquerque’s dry climate. Highly drought-tolerant and low-maintenance, they thrive in well-drained soils and full sun. Their dense foliage provides year-round color and structure, making them a durable and visually appealing addition to high desert landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "3-7",
      matureSize: "15' x 6'",
      native: true
    },
    {
      id: 5,
      name: "Spartan Juniper",
      scientificName: "Juniperus chinensis 'Spartan'",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Juniper.jpg",
      description: "Spartan junipers are elegant, fast-growing evergreens prized for their narrow, columnar shape and rich green foliage. These Junipers are ideal for privacy screens, windbreaks, or as striking vertical accents in Albuquerque’s arid climate. Drought-tolerant and low-maintenance, they thrive in full sun and well-drained soils, requiring little pruning to maintain their sleek form. Their resilience and year-round greenery make them a versatile and attractive choice for high desert landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-9",
      matureSize: "15' x 5'",
      native: false
    },
    {
      id: 6,
      name: "Utah Juniper",
      scientificName: "Juniperus osteosperma",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/utahJuniper.jpg",
      description: "Utah junipers are rugged, drought-tolerant evergreens that thrive in Albuquerque’s harsh high desert. With their gnarly, twisted, and irregular growth, they stand out with dense, gray-green foliage and small, berry-like cones. These trees feature thick, woody trunks and branches, resilient to poor soils, intense sun, and minimal water. Often seen growing seemingly straight through rocks and mountainsides, Utah junipers create a striking spectacle, their enduring presence adding a dramatic touch to the landscape.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-9",
      matureSize: "20' x 20'",
      native: true
    },
    {
      id: 7,
      name: "Rocky Mountain Juniper",
      scientificName: "Juniperus scopulorum",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/rockmtnJuniper.jpg",
      description: "Following the mountain range down from Colorado, Rocky Mountain junipers are hardy evergreens that flourish in Albuquerque’s high desert. With their dense, blue-green foliage and distinctive, twisted branches, they offer a rugged, picturesque presence in any landscape. Known for their resilience, these junipers thrive in poor soils, intense sun, and low water conditions, making them an excellent choice for xeriscaping. Their small, berry-like cones attract wildlife, while their deep roots help stabilize soil, making them a practical and visually appealing addition to New Mexico’s rugged terrain.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "3-7",
      matureSize: "30' x 10'",
      native: true
    },
    {
      id: 8,
      name: "One Seed Juniper",
      scientificName: "Juniperus monosperma",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/oneseedJuniper.jpg",
      description: "One-seed junipers, also known as the New Mexico Juniper, are tough, drought-tolerant evergreens native to the high desert of New Mexico. Preferring higher altitudes, this resilient tree is found in limited areas outside of the state. With its bushy, upright form that grows from the ground up, the One-seed juniper offers a unique, striking shape that adds character to any yard or landscape. Well-suited for Albuquerque's intense sun, poor soils, and minimal water, it is an ideal choice for xeriscaping.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "4-8",
      matureSize: "15' x 10'",
      native: true
    },
  ],

  // cedars: [
  //   {
  //     id: 4,
  //     name: "Red Cedar",
  //     scientificName: "Juniperus scopulorum",
  //     image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Juniper.jpg",
  //     description: "A drought-tolerant conifer with dense foliage and berries...",
  //     sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
  //     sunRequirement: "Full Sun",
  //     waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/medHiWater.png",
  //     waterRequirement: "Moderate Water",
  //     hardinessZones: "2-8",
  //     matureSize: "60' x 20'",
  //   },
  //   {
  //     id: 4,
  //     name: "Deodor Cedar",
  //     scientificName: "Cedrus deodara",
  //     image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Juniper.jpg",
  //     description: "A drought-tolerant conifer with dense foliage and berries...",
  //     sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
  //     sunRequirement: "Full Sun",
  //     waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/medHiWater.png",
  //     waterRequirement: "Moderate Water",
  //     hardinessZones: [4, 5, 6, 7],
  //     matureSize: "10' x 20'",
  //   },
  //   {
  //     id: 4,
  //     name: "Atlas Cedar",
  //     scientificName: "Cedrus deodara",
  //     image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Juniper.jpg",
  //     description: "A drought-tolerant conifer with dense foliage and berries...",
  //     sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
  //     sunRequirement: "Full Sun",
  //     waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/medHiWater.png",
  //     waterRequirement: "Moderate Water",
  //     hardinessZones: [4, 5, 6, 7],
  //     matureSize: "10' x 20'",
  //   },
  //   {
  //     id: 4,
  //     name: "Cypress Cedar",
  //     scientificName: "Cedrus deodara",
  //     image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/Juniper.jpg",
  //     description: "A drought-tolerant conifer with dense foliage and berries...",
  //     sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
  //     sunRequirement: "Full Sun",
  //     waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/medHiWater.png",
  //     waterRequirement: "Moderate Water",
  //     hardinessZones: [4, 5, 6, 7],
  //     matureSize: "10' x 20'",
  //   },
  // ],
  spruces: [
    {
      id: 9,
      name: "Blue Spruce",
      scientificName: "Picea pungens",
      image: "https://jfschmidt.com/wp-content/uploads/2023/03/picea-fatalbert-web-01.jpg",
      description: "Blue spruce is a striking evergreen known for its silvery-blue needles and symmetrical, conical shape. Native to the Rocky Mountain region, this tree thrives in Albuquerque’s high desert, offering year-round color and structure to landscapes. With its dense foliage and strong, upright growth, blue spruce makes an excellent choice for privacy screens, windbreaks, or ornamental accents anywhere from Santa Fe to Rio Rancho.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "2-8",
      matureSize: "60' x 20'",
      native: true
    },
    {
      id: 10,
      name: "Engelmann Spruce",
      scientificName: "Picea engelmannii",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/engelmannSpruce.jpg",
      description: "Engelmann spruce is a hardy evergreen native to the Rocky Mountains, known for its slender, conical shape and soft, bluish-green needles. While it thrives in cooler, higher elevations, it can perform well in the Albuquerque and Santa Fe regions, where it adds dense, symmetrical growth and vibrant color to the landscape. Ideal for windbreaks or privacy screens, Engelmann spruce is adaptable to various soil types and tolerates both drought and cold conditions.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "2-7",
      matureSize: "80' x 15'",
      native: true
    },
    {
      id: 11,
      name: "Sitka Spruce",
      scientificName: "Picea sitchensis",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/sitka.jpg",
      description: "Sitka spruce is a majestic evergreen native to the Pacific Northwest, known for its tall, straight trunk and soft, bluish-green needles. While it typically thrives in cooler, coastal climates, it can adapt to Albuquerque and Santa Fe regions with the right care. Its dense, pyramid-shaped canopy provides excellent shade, privacy, and wind protection. Sitka spruce prefers moist, well-drained soils and does best in areas with moderate temperatures, so it may not fare as well in the hotter, drier climates of southern New Mexico, such as Las Cruces.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "7-8",
      matureSize: "150' x 35'",
      native: false
    },
    {
      id: 12,
      name: "Norway Spruce",
      scientificName: "Picea abies",
      image: "https://jfschmidt.com/wp-content/uploads/2023/06/picea-columnarnorway-web-01.jpg",
      description: "Norway spruce is a tall, fast-growing evergreen known for its symmetrical, pyramidal shape and dense, dark green needles. This tree features drooping branches and long, cylindrical cones, giving it a distinctive appearance. Norway spruce is adaptable to various soil types and tolerates both drought and cold conditions once established. With its elegant form and dense foliage, it provides excellent coverage and shade, making it a striking addition to a variety of landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "3-7",
      matureSize: "50' x 25'",
      native: false
    },
  ],
  cypress: [
    {
      id: 13,
      name: "Italian Cypress",
      scientificName: "Cupressus sempervirens",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/italianCypress.jpg",
      description: "The Italian Cypress is a drought-tolerant evergreen that thrives in Albuquerque’s arid climate, making it ideal for windbreaks, privacy screens, or vertical accents. It requires full sun and well-draining soil, with low to moderate water needs once established. Preferring deep but infrequent watering, this low-maintenance tree resists deer and most pests while adding year-round greenery and a Mediterranean touch to the landscape.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "7-10",
      matureSize: "45' x 5'",
      native: false
    },
    {
      id: 14,
      name: "Arizona Cypress",
      scientificName: "Hesperocyparis arizonica",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/arizonaCypress.jpeg",
      description: "The Arizona Cypress is a hardy, drought-tolerant evergreen that thrives in Albuquerque’s dry climate, offering a fast-growing option for windbreaks, privacy screens, or specimen plantings. It requires full sun and well-draining soil, with low to moderate water needs once established. More cold-hardy than Italian Cypress, it has a distinctive blue-green color and dense foliage that provides excellent coverage. Resistant to pests and deer, this low-maintenance tree is perfect for adding year-round texture and resilience to Albuquerque landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "7-11",
      matureSize: "50' x 15'",
      native: true
    },
    {
      id: 15,
      name: "Smooth Cypress",
      scientificName: "Cupressus glabra",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/cypress-357846_1920.jpg",
      description: "The Smooth Cypress, also known as the Arizona Smooth Bark Cypress, is a drought-tolerant evergreen well-suited for Albuquerque’s arid climate. It thrives in full sun with well-draining soil and requires low to moderate water once established. Featuring striking blue-green foliage and smooth, peeling bark in shades of red and gray, it adds unique texture to landscapes. Fast-growing and resistant to pests and deer, this low-maintenance tree works well as a windbreak, privacy screen, or ornamental accent in Southwestern gardens.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
      waterRequirement: "Low Water",
      hardinessZones: "6-9",
      matureSize: "45' x 15'",
      native: true
    },
    {
      id: 16,
      name: "Leyland Cypress",
      scientificName: "Cupressocyparis leylandii",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/randomCypress.jpg",
      description: "The Leyland Cypress is a fast-growing evergreen prized for its dense, feathery foliage and rapid growth, making it a top choice for windbreaks and privacy screens. While highly adaptable, it benefits from occasional pruning to maintain its shape and prevent overcrowding. Though not as drought-hardy as some native species, it establishes quickly and provides lush, year-round greenery, making it a popular option for Southwestern landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "6-10",
      matureSize: "50' x 20'",
      native: false
    },
  ],
  arborvitaes: [
    {
      id: 17,
      name: "American Arborvitae",
      scientificName: "Thuja occidentalis",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/arborvitae-4913378_1920.jpg",
      description: "A classic evergreen with soft, scale-like foliage, American Arborvitae is a versatile choice for hedges, privacy screens, or specimen plantings. It grows in a dense, pyramidal shape and responds well to pruning, making it easy to shape and maintain. While slower-growing than some alternatives, it offers long-term beauty and resilience, with excellent cold tolerance and year-round greenery.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "2-7",
      matureSize: "30' x 15'",
      native: false
    },
    {
      id: 18,
      name: "Green Giant Arborvitae",
      scientificName: "Thuja standishii × plicata ‘Green Giant’",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/arbovitae-hedge.jpg",
      description: "This vigorous hybrid is prized for its fast growth and natural resistance to pests and disease, making it a top choice for large privacy screens and windbreaks. With lush, feathery foliage and a uniform, conical shape, Green Giant Arborvitae quickly forms an impressive living barrier. Unlike some arborvitaes, it remains dense without excessive pruning, making it a low-maintenance yet highly effective landscaping option.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "5-9",
      matureSize: "55' x 15'",
      native: false
    },
    {
      id: 19,
      name: "Emerald Arborvitae",
      scientificName: "Thuja occidentalis ‘Smaragd’",
      image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/conifer/thuja-6656032_1920.jpg",
      description: "Known for its compact, elegant form, Emerald Arborvitae maintains a slender, upright shape that requires little pruning. Its rich green foliage resists browning in winter, providing a neat and polished look year-round. A great choice for small spaces, it works well as a foundation plant, hedge, or accent in formal and modern landscapes.",
      sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
      sunRequirement: "Full Sun",
      waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
      waterRequirement: "Medium Water",
      hardinessZones: "3-8",
      matureSize: "15' x 4'",
      native: false
    },
  ],


};

export default coniferdata;

const xericshrubs =
{
    broadleafs: [
          {
            id: 1,
            name: "Algerita",
            scientificName: "Berberis trifoliolata",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/640px-Berberis_trifoliolata_berries.jpg",
            description: "Algerita is a hardy, evergreen shrub native to the southwestern United States, including New Mexico. It is characterized by its spiny, dark green leaves and small yellow flowers that bloom in early spring, followed by bright red berries in the fall. Algerita thrives in dry, rocky soil and is highly drought-tolerant once established, making it ideal for low-maintenance landscapes or xeriscaping. Its compact, rounded form makes it a great choice for borders, hedges, or wildlife-friendly plantings. Mulching can help retain moisture and protect the roots during extreme temperatures.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-9",
            matureSize: "4' x 4'",
            native: true
        },
        {
            id: 2,
            name: "Turpentine Bush",
            scientificName: "Ericameria laricifolia",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/turpentinebush.JPG",
            description: "Turpentine bush is a tough, drought-tolerant shrub native to the southwestern U.S. and northern Mexico. It grows up to three feet tall and wide, with fine, aromatic green leaves and bright yellow flowers in late summer and fall. Loved by pollinators, it thrives in poor, rocky soils with little water, making it perfect for xeric landscapes and erosion control.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "6-7",
            matureSize: "2.5' x 2.5'",
            native: false
          },
          {
            id: 3,
            name: "Curl-leaf Mountain Mahogany",
            scientificName: "Cercocarpus ledifolius",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/Cercocarpus_ledifolius_8219.jpg",
            description: "The Curl-leaf Mountain Mahogany is a hardy, evergreen shrub that thrives in New Mexico’s arid conditions. Known for its distinctive twisting, leathery leaves and silvery-gray foliage, it provides year-round interest, especially during the winter months. This drought-tolerant shrub offers beautiful, fluffy seed tails that add texture to the landscape. Ideal for low-maintenance, xeriscape gardens, it works well in rocky, well-drained soils and full sun, making it perfect for those seeking a rugged yet attractive plant for dry landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '5-9',
            matureSize: "15' x 15'",
          },
          {
            id: 4,
            name: "Alderleaf Mountain Mahogany",
            scientificName: "Cercocarpus Montanus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/Cercocarpus_montanus_kz07.jpg",
            description: "Alderleaf Mountain Mahogany is a deciduous shrub that adds texture and structure to New Mexico gardens with its oval, dark green leaves and silky, feathery seed heads. This variety thrives in well-drained soils and full sun, offering both wildlife value and low-maintenance beauty. Its dense, upright form provides great wind protection and erosion control, making it a great choice for slopes or challenging landscapes. Alderleaf is highly drought-tolerant once established, making it well-suited to the dry, high-desert environment.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '6-9',
            matureSize: "10' x 12'",
          },
          {
            id: 5,
            name: "Hairy Mountain Mahogany",
            scientificName: "CCercocarpus breviflorus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/hairy-mountain-mahogany.jpg",
            description: "Hairy Mountain Mahogany is a small, evergreen shrub that thrives in harsh, dry conditions. Known for its smooth, dark green leaves and hair-like seed tails that add elegance to the plant, it can grow as a shrub or small tree. This variety is perfect for xeriscape landscapes in New Mexico, requiring little water once established. Its hardy nature makes it ideal for slopes, dry hillsides, and rocky terrain. Hair Mountain Mahogany is also valued for its windbreak potential and wildlife habitat benefits.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '5-9',
            matureSize: "12' x 15'",
          },
          {
            id: 17,
            name: "Parney Cotoneaster",
            scientificName: "Cotoneaster lacteus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/parney-cotoneaster.jpg",
            description: "Parney Cotoneaster  is a hardy, drought-tolerant shrub perfect for New Mexico gardens. Thriving in full sun with well-draining soil, it features dark green foliage, spring flowers, and bright red winter berries that attract birds. Its arching branches create a graceful, layered look, making it ideal for hedges, windbreaks, or accent plantings. Low-maintenance and heat-resistant, it’s a great choice for water-wise landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '7-9',
            matureSize: "12' x 12'",
          },
        {
            id: 18,
            name: "Coral Beauty Cotoneaster",
            scientificName: "Cotoneaster dammeri",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/dammeri-cotoneaster.jpg",
            description: "Dammeri Cotoneaster is a tough, low-growing evergreen shrub perfect for New Mexico gardens. Thriving in full sun to partial shade, it spreads quickly, forming a dense mat of glossy green leaves that help with erosion control. In spring, it produces small white flowers, followed by bright red berries in fall that attract birds. Drought-tolerant and low-maintenance, it’s an excellent choice for ground cover, slopes, or borders in water-wise landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '5-10',
            matureSize: "2' x 6'",
          },
        {
            id: 19,
            name: "Rockspray Cotoneaster",
            scientificName: "Cotoneaster horizontalis",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/cotoneaster-4506645_1920.jpg",
            description: "Rockspray Cotoneaster is a hardy, drought-tolerant shrub well-suited to New Mexico gardens. Its distinctive branching pattern creates a striking, layered look, making it ideal for ground cover, slopes, or cascading over walls. In spring, it produces small pinkish-white flowers, followed by bright red berries in fall that attract birds. Thriving in full sun with well-draining soil, this low-maintenance shrub adds year-round interest to water-wise landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: '5-7',
            matureSize: "2' x 6'",
          },
          {
            id: 8,
            name: "Pyracantha",
            scientificName: "Pyracantha coccinea",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/deciduous/firethorn-5758151_1920.jpg",
            description: "Pyracantha, commonly known as firethorn, is a vigorous, evergreen shrub with spiny branches and dense foliage. It produces clusters of white flowers in the spring, followed by vibrant red, orange, or yellow berries in the fall that attract birds. This hardy shrub can be used for hedges, screens, or as a cascading ground cover. It thrives in full sun and well-drained soil, and once established, it’s drought-tolerant. Pyracantha also benefits from occasional pruning to maintain its shape and encourage better flowering and berry production. Mulching around the base helps conserve moisture and protect roots from extreme temperatures.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "6-9",
            matureSize: "10' x 10'",
          },
  
        ],
    flowering: [
      {
        id: 9,
        name: "Black Knight Butterfly Bush",
        scientificName: "Buddleia davidii 'Black Knight'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/butterflybush-dark-purple.jpg",
        description: "With its deep purple, almost velvety blooms, Black Knight is one of the most striking butterfly bushes for New Mexico gardens. Its rich color contrasts beautifully with desert landscapes, and its long, fragrant flower spikes attract butterflies, hummingbirds, and bees all season. This fast-growing shrub benefits from occasional pruning to maintain shape and encourage fresh blooms, making it a bold yet low-maintenance addition to pollinator-friendly gardens and one of Jericho Nursery's best sellers.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "6' x 6'",
      },
      {
        id: 10,
        name: "Autumn Sage",
        scientificName: "Salvia greggii",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/autumn-sage.jpg",
        description: "Autumn Sage is a hardy, drought-tolerant perennial perfect for New Mexico gardens. Blooming from spring to fall, it produces vibrant red, pink, or purple flowers that attract hummingbirds and pollinators. Thriving in full sun and well-drained soil, it requires minimal water once established. Its aromatic foliage and compact growth make it ideal for borders, xeriscapes, or wildlife gardens, adding long-lasting color to the landscape.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "2' x 3'",
      },
      {
        id: 39,
        name: "Western Sand Cherry",
        scientificName: "Prunus besseyi",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/prunus-pumilia.JPG",
        description: "The Western Sand Cherry is a hardy, compact shrub that thrives in the dry, rocky soils of New Mexico. Known for its white spring blossoms, it produces dark purple to black edible cherries that attract birds and wildlife. Its glossy, dark green foliage transitions to vibrant autumn colors, adding seasonal interest. This shrub is drought-tolerant once established and does well in full sun and well-drained soil, making it an excellent choice for xeriscape gardens or as a low-maintenance shrub in the landscape.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-7",
        matureSize: "6' x 6'",
      },

        
          {
            id: 13,
            name: "Compact Texas Sage",
            scientificName: "Leucophyllum frutescens 'Compacta'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/purple-texas-sage.jpg",
            description: "Compact Texas Sage is a hardy, drought-tolerant shrub perfect for New Mexico’s arid climate. It thrives in full sun with well-drained soil, requiring little water once established. Its silvery-green foliage contrasts beautifully with its vibrant purple flowers, which bloom after summer rains and attract pollinators. With a dense, rounded form, it’s ideal for low-maintenance hedges, borders, or xeriscapes, adding year-round interest to the landscape.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-10",
            matureSize: "3' x 3'",
          },
          {
            id: 14,
            name: "Mexican Bird of Paradise",
            scientificName: "Caesalpinia mexicana",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/flowering/bird-of-paradise-bush-3494940_1920.jpg",
            description: "The Mexican Bird of Paradise is an exotic, drought-tolerant plant known for its striking, bird-like flowers in shades of orange and yellow. It thrives in New Mexico’s sunny, dry climate and adds a dramatic flair to gardens with its upright, lush foliage. While it prefers full sun, it’s adaptable to other growing conditions if needed. Jericho recommends using this plant as a xeric tree or shrub to enhance the color in your xeriscaped sections of yard or garden.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-10",
            matureSize: "10' x 10'",
            native: true
          },
     
          {
            id: 17,
            name: "Grosso Hybrid Lavender",
            scientificName: "Lavandula x intermedia ‘Grosso’",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/hybrid-lavender.jpg",
            description: "‘Grosso’ lavender is a hardy, drought-tolerant hybrid ideal for New Mexico gardens. Thriving in full sun and well-draining soil, it produces long-stemmed, highly fragrant purple flowers from summer to fall, attracting bees and butterflies. More heat and cold-tolerant than English lavender, ‘Grosso’ requires minimal watering once established. Its high oil content makes it popular for sachets, essential oils, and dried arrangements, adding both beauty and function to xeriscapes and herb gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-9",
            matureSize: "3' x 3'",
          },
   
          {
            id: 19,
            name: "Flowering Quince",
            scientificName: "Chaenomeles speciosa",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/flowering-quince-4139038_1920.jpg",
            description: "Flowering quince is a hardy, drought-tolerant shrub that thrives in New Mexico’s arid climate. Blooming in late winter to early spring, its vibrant red, pink, or orange flowers attract pollinators. It prefers well-draining soil and full sun but adapts to various conditions. Once established, it requires little water, making it ideal for xeriscaping. In fall, it produces small, aromatic fruits that can be used for jellies or left for wildlife. With its spiny branches, flowering quince also works well as a natural barrier or hedge.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-9",
            matureSize: "6' x 6'",
          },
          {
            id: 20,
            name: "Russian Sage",
            scientificName: "Perovskia atriplicifolia",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/russian-sage-6602342_1920.jpg",
            description: "Russian Sage is a tough, drought-tolerant perennial that thrives in New Mexico’s hot, dry climate. Its tall, airy spikes of lavender-blue flowers bloom from summer to fall, attracting bees and butterflies. With silvery, fragrant foliage, it adds texture and contrast to xeriscapes and low-water gardens. Thriving in full sun and well-drained soil, it’s a low-maintenance choice for adding long-lasting color to the landscape.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-9",
            matureSize: "4' x 3'",
          },
        {
            id: 21,
            name: "Coralberry Shrub",
            scientificName: "Symphoricarpos orbiculatus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/coralberry.jpg",
            description: "Coralberry shrub is a hardy, drought-tolerant plant that thrives in New Mexico’s dry, rocky soils. Known for its clusters of small, pink to coral-colored berries that appear in late summer and persist through fall, it adds seasonal interest and attracts birds. Coralberry prefers full sun to partial shade and well-draining soil, requiring minimal water once established. This low-maintenance shrub works well in xeriscaping, native plant gardens, or as a groundcover, providing both beauty and resilience to arid landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "2-7",
            matureSize: "3' x 4'",
            native: false
          },
          {
            id: 22,
            name: "Mexican Blue Sage",
            scientificName: "Salvia chamaedryoides",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Salviachamaedryoides.jpg",
            description: "Mexican Blue Sage is a drought-tolerant perennial that thrives in New Mexico’s hot, dry climate. It features striking blue flowers from spring to fall, attracting bees, butterflies, and hummingbirds. With silvery-green foliage and a compact, mounding habit, it’s perfect for xeriscapes, borders, or rock gardens. Thriving in full sun and well-drained soil, this low-maintenance sage adds vibrant color and texture to any landscape.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-10",
            matureSize: "3' x 4'",
          },
       
      
        ],
    grasses:[
      {
        id: 23,
        name: "Blue Avena",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 24,
        name: "Switchgrass",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 25,
        name: "Galleta",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 26,
        name: "Giant Sacaton",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 27,
        name: "Pinky Flamingo Muhly",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 28,
        name: "Silky Threadgrass",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 29,
        name: "Little Bluestem",
        scientificName: "Vitex agnus-castus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/vitex.jpg",
        description: "The Shoal Creek Chaste Tree (Vitex agnus-castus ‘Shoal Creek’) is a heat- and drought-tolerant ornamental tree that thrives in Albuquerque’s arid climate. Growing 10–15 feet tall and wide, it forms a multi-trunked, vase-like shape with fragrant, gray-green foliage. From late spring through summer, it produces showy spikes of violet-blue flowers, attracting pollinators like bees and butterflies. It prefers full sun and well-draining soil, requiring low to moderate water once established.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-9",
        matureSize: "20' x 20'",
        native: false
      },

    ],

    hardxeric: [
    
       
     
        {
            id: 30,
            name: "Fringed Sage",
            scientificName: "Salvia subincisa",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/fringed-sage.jpg",
            description: "Fringed sage is a drought-tolerant, low-maintenance perennial that flourishes in New Mexico’s dry climate. Known for its delicate, fringed blue to lavender flowers, it adds a soft touch of color to gardens from late spring to summer. Preferring full sun and well-draining soil, fringed sage requires minimal water once established. This fragrant, attractive plant is perfect for xeriscaping and pollinator gardens, as it attracts bees, butterflies, and hummingbirds.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-10",
            matureSize: "1' x 1.5'",
            native: false
          },
        {
            id: 31,
            name: "Curry Plant",
            scientificName: "Helichrysum italicum",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/curry-plant.jpg",
            description: "Curry plant is a fragrant, drought-tolerant shrub well-suited for New Mexico’s dry climate. Known for its silvery-gray, narrow leaves that emit a curry-like scent when crushed, it adds both aroma and texture to gardens. This low-maintenance plant thrives in full sun and well-draining soil, requiring minimal water once established. Its yellow, button-like flowers bloom in summer, attracting pollinators like bees and butterflies. Perfect for xeriscaping, herb gardens, or as an ornamental shrub, the curry plant is a great addition to low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "8-11",
            matureSize: "1.5' x 2.5'",
            native: false
          },
        {
            id: 32,
            name: "Big Sagebrush",
            scientificName: "Artemisia tridentata",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/Big%20Sagebrush.jpg",
            description: "Big sagebrush is a hardy, drought-tolerant shrub native to the arid regions of New Mexico. Known for its silvery-gray foliage and distinct, aromatic scent, it thrives in full sun and well-draining, sandy soils. Big sagebrush is well-adapted to New Mexico's dry climate, requiring minimal water once established. This evergreen shrub is an essential part of local ecosystems, providing habitat and food for wildlife. With its rugged beauty and resilience, big sagebrush is ideal for xeriscaping and native plant gardens.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-11",
            matureSize: "4' x 4'",
            native: false
          },
          {
            id: 33,
            name: "Apache Plume",
            scientificName: "Fallugia paradoxa",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/apache-plume-1937218_1920.jpg",
            description: "Apache Plume is a striking shrub native to the Southwestern United States, valued for its elegant, feathery plumes that appear in late spring and early summer. These plumes are followed by small white flowers that provide a delicate contrast to its gray-green, aromatic foliage. Well-suited for New Mexico’s dry, rocky landscapes, Apache Plume thrives in full sun and well-drained soils, making it an excellent choice for xeriscape gardens. This drought-tolerant shrub attracts pollinators and offers year-round interest with its unique form and beautiful seed heads, which persist into the winter.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-10",
            matureSize: "5' x 5'",
          },
        {
            id: 34,
            name: "Three-leaf Sumac",
            scientificName: "Rhus trilobata",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/three-leaf-sumac.jpg",
            description: "Three-leaf sumac is a hardy, drought-tolerant shrub native to New Mexico. Known for its trifoliate leaves that turn vibrant red and orange in the fall, it adds seasonal interest and color to gardens. This low-maintenance plant thrives in full sun to partial shade and well-draining soil, requiring minimal water once established. Three-leaf sumac is perfect for xeriscaping, providing a natural barrier or groundcover, and its small, clustered berries attract birds. Its resilience to heat and arid conditions makes it a great addition to low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-8",
            matureSize: "4' x 7'",
            native: false
          },
          {
            id: 35,
            name: "Dwarf Fragrant Sumac",
            scientificName: "Rhus aromatica 'Gro-Low'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/dwarf-fragrant-sumac.jpg",
            description: "Dwarf fragrant sumac is a low-growing, drought-tolerant shrub ideal for New Mexico gardens. Known for its aromatic, glossy leaves and bright red to yellow foliage in the fall, it adds color and texture throughout the year. This compact variety thrives in full sun to partial shade and well-draining soil, requiring minimal water once established. Dwarf fragrant sumac works well as a groundcover or low hedge and attracts birds with its small, reddish berries. Its low-maintenance nature and resilience to dry conditions make it perfect for xeriscaping.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-9",
            matureSize: "2.5' x 6'",
          },
          {
            id: 36,
            name: "Rosemary",
            scientificName: "Salvia rosmarinus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/rosemary-4912663_1920.jpg",
            description: "Rosemary is a hardy, drought-tolerant evergreen herb that thrives in New Mexico’s arid climate. It prefers full sun and well-drained soil, requiring minimal water once established. With fragrant, needle-like leaves and small blue flowers that attract pollinators, it’s both ornamental and useful for cooking. Its compact, woody growth makes it perfect for borders, xeriscapes, or container gardens, adding year-round greenery and resilience to the landscape.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-10",
            matureSize: "3' x 3'",
          },
        {
            id: 37,
            name: "Occotillio",
            scientificName: "Fouquieria splendens",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/shrubs/occotillio.jpg",
            description: "Ocotillo is a striking, drought-tolerant shrub native to New Mexico’s arid landscapes. Known for its tall, spiny stems that can reach up to 20 feet, ocotillo produces vibrant red tubular flowers in spring, attracting hummingbirds and pollinators. This unique plant thrives in full sun and well-draining, sandy or rocky soils, requiring minimal water once established. Ocotillo’s striking appearance and ability to withstand extreme heat make it a standout in xeriscapes and desert gardens, providing both beauty and resilience to low-water landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "8-11",
            matureSize: "15' x 8'",
            native: false
          },
          {
            id: 38,
            name: "Fern Bush",
            scientificName: "Pinus edulis",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/fernbush.JPG",
            description: "Fern Bush is a native New Mexico shrub valued for its feathery, fern-like foliage and delicate, creamy-white flowers that bloom in late spring to early summer. Known for its ability to thrive in arid conditions, this shrub is highly drought-tolerant once established and grows best in well-drained soils with full sun. It’s perfect for xeriscaping, offering a graceful texture and providing wildlife habitat with its fragrant flowers that attract pollinators. With minimal maintenance, Fern Bush adds a soft, airy element to dry, desert landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "4-8",
            matureSize: "4' x 4'",
          },
          {
            id: 39,
            name: "Chamisa",
            scientificName: "Chrysothamnus nauseosus",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/needles-district-rabbitbrush-4009467_1920.jpg",
            description: "Chamisa, sometimes known as Rabbit Brush, is a native shrub that flourishes in New Mexico’s dry, rocky soils. Known for its bright yellow flowers that bloom in late summer to fall, it provides a cheerful burst of color when many other plants are past their prime. This drought-tolerant shrub thrives in full sun and well-drained soils, making it ideal for xeriscaping or low-maintenance gardens. Rabbit Brush attracts pollinators and offers a natural, wild look to desert landscapes, with its silvery-green foliage adding texture and contrast. Its resilience to harsh conditions makes it a perfect fit for arid environments.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "3-11",
            matureSize: "6' x 6'",
          },
          {
            id: 40,
            name: "Brakelights Red Yucca",
            scientificName: "Hesperaloe parviflora 'Brakelight'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Shrubs/flower/Red-Yucca.jpg",
            description: "Brakelight Red Yucca is a striking, drought-tolerant succulent that thrives in New Mexico’s dry climate. Known for its vibrant red tubular flowers that bloom on tall spikes during the summer, it adds a bold pop of color to xeriscape gardens. The plant’s narrow, spiky foliage forms a clumping rosette, offering an architectural element to landscapes. Brakelight Red Yucca thrives in full sun and well-drained soils, requiring minimal water once established. Its low-maintenance nature and ability to attract hummingbirds make it an excellent choice for low-water gardens or desert landscapes.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "5-11",
            matureSize: "4' x 5'",
          },

        ],
}
export default xericshrubs
const cactusdata =
{
    cactus: [
    {
        id: 1,
        name: "Santa Rita Prickly Pear Cactus",
        scientificName: "Opuntia santa-rita",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/PRICKLY_PEAR,_SANTA_RITA_(Opuntia_santa-rita)_(5-20-11)_-01_(5741328146).jpg",
        description: "Santa Rita prickly pear cactus  is a hardy, drought-tolerant cactus well-suited for New Mexico’s arid climate. Known for its striking purple to pinkish pads and vibrant yellow flowers in the spring, it adds bold color and texture to gardens. This low-maintenance cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Its edible, sweet-tasting fruits, called 'tunas' attract wildlife and can be harvested for jams or drinks. The Santa Rita prickly pear is perfect for xeriscaping, providing both beauty and resilience to low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "7-10",
        matureSize: "5' x 7'",
        native: false
    },
    {
        id: 2,
        name: "Spineless Prickly Pear Cactus",
        scientificName: "Opuntia ellisiana",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/spineless-pricklypear.jpg",
        description: "Spineless prickly pear cactus is a hardy, low-maintenance cactus that thrives in New Mexico’s dry climate. Unlike many other prickly pears, it has smooth, spineless pads, making it easier to handle. This drought-tolerant plant produces vibrant yellow to orange flowers in spring and edible, purple fruits (called 'tunas') in summer. Spineless prickly pear prefers full sun and well-draining soil, requiring minimal water once established. Ideal for xeriscaping, this cactus adds texture and color to low-water landscapes while providing food for wildlife and humans alike.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-10",
        matureSize: "4' x 7'",
        native: false
    },
    {
        id: 3,
        name: "Engelmann Prickly Pear Cactus",
        scientificName: "Opuntia engelmannii",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/Engelmann-prickly-pear.jpg",
        description: "Engelmann prickly pear is a resilient, drought-tolerant cactus that thrives in New Mexico’s arid climate. Known for its large, flat pads and beautiful yellow flowers that bloom in spring, it also produces sweet, edible red to purple fruits called 'tunas.' This cactus prefers full sun and well-draining, rocky soils, requiring minimal water once established. Engelmann prickly pear is perfect for xeriscaping, adding both color and texture to low-water landscapes while attracting pollinators and wildlife. Its hardy nature makes it a valuable addition to desert and native plant gardens.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "8-10",
        matureSize: "4' x 8'",
        native: false
    },
    {
        id: 4,
        name: "Tree Cholla Cactus",
        scientificName: "Cylindropuntia imbricata",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/Cylindropuntia_imbricata_1.jpg",
        description: "Tree Cholla is a rugged, drought-tolerant cactus that thrives in New Mexico’s arid climate. Its branching, tree-like form adds striking texture to the landscape, while vibrant magenta flowers bloom in late spring, followed by yellow fruit that provides food for wildlife. The spiny, segmented stems offer natural protection and make it an excellent choice for xeriscaping, erosion control, or wildlife-friendly gardens. Adapted to full sun and poor soils, Tree Cholla is a resilient, low-maintenance addition to any desert landscape.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-10",
        matureSize: "8' x 5'",
        native: false
    },
    {
        id: 4,
        name: "Whipple Cholla Cactus",
        scientificName: "Cylindropuntia whipplei",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/whipple-cholla.jpg",
        description: "Whipple Cholla is a striking, drought-tolerant cactus native to the southwestern U.S. It features cylindrical, spiny stems that can form dense clusters, with vibrant magenta or pink flowers appearing in late spring to early summer. After blooming, it produces reddish or yellowish fruit that attracts wildlife. Whipple Cholla thrives in full sun and well-draining soil, making it a great choice for xeriscaping or desert landscapes. Its spiny texture provides a unique architectural element, perfect for adding character to dry, low-maintenance gardens.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-10",
        matureSize: "4' x 3'",
        native: false
    },
    {
        id: 4,
        name: "Santa Fe Cholla Cactus",
        scientificName: "Cylindropuntia viridiflora",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/santa-fe-cholla.jpg",
        description: "Cylindropuntia viridiflora, commonly known as Green-Flowered Cholla or Santa Fe Cholla, is a striking cactus native to the southwestern U.S. It features long, cylindrical stems covered in sharp spines, forming a dense, bushy appearance. In late spring to early summer, it blooms with vibrant orange flowers, followed by red to orange fruit that attracts wildlife. Green-Flowered Cholla thrives in full sun and well-draining soil, making it an ideal choice for xeriscaping or desert gardens, offering a low-maintenance, drought-tolerant solution for water-wise landscaping.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-10",
        matureSize: "4' x 3'",
        native: false
    },
    {
        id: 5,
        name: "Cane Cholla Cactus",
        scientificName: "Cylindropuntia spinosior",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/cane-cholla.jpg",
        description: "Cane cholla is a hardy, drought-tolerant cactus native to New Mexico’s desert regions. Known for its tall, cylindrical stems and dense spines, it provides a unique architectural element to xeriscapes. In spring, cane cholla produces bright pink to magenta flowers that attract pollinators, followed by small, red fruits. This cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Cane cholla is perfect for low-water landscapes, adding both texture and vibrant color while providing shelter and food for local wildlife.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-11",
        matureSize: "6' x 3'",
        native: false
    },
    {
        id: 6,
        name: "Staghorn Cholla Cactus",
        scientificName: "Cylindropuntia versicolor",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/staghorn-cholla.jpg",
        description: "Staghorn cholla is a striking, drought-tolerant cactus native to New Mexico’s desert landscapes. Known for its unique, branching stems that resemble the antlers of a stag, it has dense spines that can detach easily, making it a bit tricky to handle. In the spring, staghorn cholla produces bright yellow to pink flowers, followed by reddish fruits that attract birds and other wildlife. This cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Staghorn cholla adds architectural interest and vibrant color to xeriscapes and low-water gardens.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "8-10",
        matureSize: "4' x 3'",
        native: false
    },
    {
        id: 7,
        name: "Golden Barrel Cactus",
        scientificName: "Echinocactus grusonii",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/cactus-404362_1920.jpg",
        description: "Golden barrel cactus is a striking, drought-tolerant cactus that thrives in New Mexico’s arid climate. Known for its rounded, globe-shaped form and bright yellow spines, it adds a bold and architectural touch to gardens. In late spring to early summer, golden barrel cactus produces yellow flowers that bloom at the top of the plant. It prefers full sun and well-draining, rocky soils, requiring minimal water once established. This cactus is perfect for xeriscaping, providing both visual interest and resilience to low-water landscapes while attracting pollinators.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "9-11",
        matureSize: "3' x 2'",
        native: false
    },
    {
        id: 8,
        name: "Fishhook Barrel Cactus",
        scientificName: "Ferocactus wislizeni",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/fishhook-barrel-cactus-4025082_1920.jpg",
        description: "Fishhook barrel cactus is a hardy, drought-tolerant cactus native to the deserts of New Mexico. Known for its large, cylindrical shape and prominent, hooked spines that resemble fishhooks, it creates a striking focal point in any landscape. In late spring to early summer, it produces vibrant yellow to red flowers that bloom at the crown, followed by edible, red fruits. This cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Fishhook barrel cactus is an excellent choice for xeriscaping, adding texture and color to low-water gardens while attracting pollinators.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "9-11",
        matureSize: "5' x 3'",
        native: false
    },
    {
        id: 9,
        name: "California Barrel Cactus",
        scientificName: "Ferocactus cylindraceus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/california-barrel-cactus-7361420_1920.jpg",
        description: "California barrel cactus is a drought-tolerant, striking cactus native to the southwestern United States, including New Mexico. Known for its large, cylindrical shape and prominent, curved spines, it creates an eye-catching focal point in gardens. In late spring to early summer, it produces bright yellow to red flowers at the top of the plant, followed by red to orange fruits. California barrel cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Ideal for xeriscaping, it adds architectural interest and vibrant color to low-water landscapes while attracting pollinators.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "9-11",
        matureSize: "6' x 1.5'",
        native: false
    },
    {
        id: 10,
        name: "Claret Cup Cactus",
        scientificName: "Echinocereus triglochidiatus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/cactus-7793604_1920.jpg",
        description: "Claret cup cactus is a striking, drought-tolerant cactus native to the southwestern United States, including New Mexico. Known for its ribbed, cylindrical stems and vibrant, cup-shaped red to orange flowers, it adds brilliant color to gardens, especially in the spring when it blooms. The cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Claret cup cactus is perfect for xeriscaping, adding texture and beauty to low-water landscapes while attracting pollinators like bees and hummingbirds. It also produces small, red fruits that attract wildlife.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-10",
        matureSize: "2' x 3'",
        native: false
    },
    {
        id: 11,
        name: "Strawberry Hedgehog Cactus",
        scientificName: "Echinocereus stramineus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/640px-Cactus_Hedgehog_AZ_Blooming_01.jpg",
        description: "Strawberry hedgehog cactus is a small, drought-tolerant cactus native to the southwestern United States, including New Mexico. Known for its cylindrical shape covered in spines that resemble a hedgehog, it produces stunning, bright pink to magenta flowers in late spring to early summer. These vibrant blooms contrast beautifully with the plant's golden spines. Strawberry hedgehog thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Ideal for xeriscaping, it adds striking color and texture to low-water landscapes while attracting pollinators like bees and hummingbirds.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "10-11",
        matureSize: "1' x 2'",
        native: false
    },
    {
        id: 12,
        name: "Texas Rainbow Cactus",
        scientificName: "Echinocereus pectinatus",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/E._dasyacanthus_4.jpg",
        description: "Texas rainbow cactus is a colorful, drought-tolerant cactus native to the southwestern United States. Known for its vibrant, rainbow-colored spines that range from yellow to pink, it adds a stunning visual element to any garden. In late spring to early summer, the cactus produces bright magenta to purple flowers, further enhancing its beauty. Texas rainbow cactus thrives in full sun and well-draining, rocky soils, requiring minimal water once established. Ideal for xeriscaping, it provides both texture and vivid color to low-water landscapes, while attracting pollinators such as bees and hummingbirds.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "8-10",
        matureSize: "4-10 in. x 2-3 in.",
        native: false
    },



    ],
    yuccas: [
    {
        id: 14,
        name: "Adam's Needle Yucca",
        scientificName: "Yucca filamentosa",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/adam's-needle.jpg",
        description: "Adam's needle yucca is a hardy, drought-tolerant plant native to the southeastern United States, but it also thrives in New Mexico's arid climate. Known for its long, sword-like leaves with thread-like filaments along the edges, it forms a rosette of striking foliage. In late spring to early summer, Adam's needle yucca produces tall, creamy-white flower spikes that add a dramatic vertical element to gardens. This low-maintenance plant prefers full sun and well-draining, sandy or rocky soils, requiring minimal water once established. It is perfect for xeriscaping, offering both architectural interest and resilience in low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-10",
        matureSize: "4' x 4'",
        native: false
    },
    {
        id: 15,
        name: "Narrow-leaf Yucca",
        scientificName: "Yucca angustifolia",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/narrow-leaf-yucca-in-arizona-3812548_1920.jpg",
        description: "Narrow leaf yucca is a drought-tolerant, evergreen plant that thrives in New Mexico’s dry climate. Known for its long, narrow, and sharp-tipped leaves, it forms a compact rosette that adds architectural interest to gardens. In late spring or early summer, narrow leaf yucca produces tall flower spikes with clusters of creamy-white blooms, attracting pollinators such as bees and hummingbirds. This yucca prefers full sun and well-draining, sandy or rocky soils, requiring minimal water once established. It’s perfect for xeriscaping, adding texture and color to low-water landscapes while being resilient to heat and drought.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "3-8",
        matureSize: "3' x 3'",
        native: false
    },
    {
        id: 16,
        name: "Red Yucca",
        scientificName: "Hesperaloe parviflora",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/red-yucca.jpg",
        description: "Red yucca is a hardy, drought-tolerant plant native to the southwestern United States, including New Mexico. Known for its slender, arching leaves and tall, branching flower spikes, it produces striking red to coral-colored tubular flowers that bloom in late spring and summer, attracting hummingbirds and pollinators. Red yucca thrives in full sun and well-draining, rocky or sandy soils, requiring minimal water once established. This low-maintenance plant is perfect for xeriscaping, adding vibrant color and vertical interest to low-water landscapes while being highly resilient to heat and drought.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-10",
        matureSize: "3' x 5'",
        native: false
    },
    {
        id: 17,
        name: "Navajo Yucca",
        scientificName: "Yucca baccata",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/navajo-yucca.jpg",
        description: "Navajo yucca is a drought-tolerant, evergreen plant native to the southwestern United States, including New Mexico. Known for its thick, sword-like leaves that form a rosette, it also produces striking white or greenish flowers in tall spikes during late spring to summer. These flowers attract pollinators like bees and hummingbirds. Navajo yucca thrives in full sun and well-draining, rocky soils, requiring minimal water once established. It's an excellent choice for xeriscaping, offering both architectural interest and resilience in low-water landscapes while being well-suited to New Mexico's dry, hot conditions.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-11",
        matureSize: "2' x 1'",
        native: false
    },
    {
        id: 18,
        name: "Soaptree Yucca",
        scientificName: "Yucca elata",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/soaptree-yucca.jpg",
        description: "Soaptree yucca is a hardy, drought-tolerant plant native to the southwestern United States, including New Mexico. Known for its tall, tree-like form, it has long, narrow, sword-shaped leaves that are arranged in a rosette. In late spring to early summer, soaptree yucca produces tall spikes of creamy white flowers that can reach up to 12 feet in height, attracting pollinators like bees and hummingbirds. The plant gets its name from the fibrous, soap-like strands found in its leaves, which were historically used by Native Americans for making soap and rope. Soaptree yucca thrives in full sun and well-draining, sandy or rocky soils, requiring minimal water once established, making it ideal for xeriscaping and low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-11",
        matureSize: "15' x 6'",
        native: false
    },
    {
        id: 19,
        name: "Beaked Yucca",
        scientificName: "Yucca periculosa",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/yucca/Yucca_rostrata_(beaked_yucca).jpg",
        description: "Beaked yucca is a drought-tolerant, evergreen plant native to the southwestern United States, including New Mexico. Known for its rosette of narrow, stiff leaves with a sharp, beak-like tip, this yucca has a unique and striking appearance. In late spring to early summer, beaked yucca produces tall flower spikes with clusters of creamy white to greenish flowers that attract pollinators such as bees and hummingbirds. It thrives in full sun and well-draining, sandy or rocky soils, requiring minimal water once established. Perfect for xeriscaping, beaked yucca adds both texture and beauty to low-water landscapes while being highly resilient to heat and drought.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "6-11",
        matureSize: "10' x 6'",
        native: false
    },

    ],
    agaves: [
    {
        id: 20,
        name: "Parry's Agave",
        scientificName: "Agave parryi",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/agave/perry's-agave.jpg",
        description: "Parry's agave is a hardy, drought-tolerant succulent native to the southwestern United States, including New Mexico. Known for its striking, compact rosette of thick, fleshy, gray-green leaves with spiny edges, it adds architectural interest to gardens. In late spring to early summer, Parry's agave sends up a tall flower stalk with clusters of yellowish-green flowers, though it blooms infrequently—typically after several years of growth. This agave thrives in full sun and well-draining, rocky or sandy soils, requiring minimal water once established. Ideal for xeriscaping, Parry's agave is a resilient, low-maintenance plant that thrives in low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "5-10",
        matureSize: "2' x 3'",
        native: false
    },
    {
        id: 21,
        name: "Century Plant",
        scientificName: "Agave americana",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/agave/century-plant.jpg",
        description: "Century agave is a large, striking succulent native to the southwestern United States and Mexico, known for its dramatic appearance. It forms a rosette of long, rigid, blue-green leaves with sharp spines along the edges, making it a bold focal point in any garden. In late spring or summer, century agave produces a towering flower stalk that can reach up to 20 feet tall, with clusters of yellow flowers, though it typically only blooms once in its lifetime after several decades of growth. Once it flowers, the plant dies, but it often produces offsets that continue its legacy. Century agave thrives in full sun and well-draining, rocky or sandy soils, requiring minimal water once established. It is ideal for xeriscaping, adding both architectural interest and resilience to low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "8-11",
        matureSize: "5' x 8'",
        native: false
    },
    {
        id: 22,
        name: "Whale's Tongue Agave",
        scientificName: "Agave ovatifolia",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/agave/whale'stongue.jpg",
        description: "Whale's tongue agave is a striking, drought-tolerant succulent native to the deserts of northern Mexico, known for its wide, smooth, blue-gray leaves that resemble the shape of a whale's tongue. Its thick, fleshy leaves form a compact rosette, which adds architectural interest to gardens. In late spring or early summer, whale's tongue agave produces a tall flower stalk that can reach up to 12 feet, with clusters of yellow-green flowers, though it blooms infrequently, usually after several years of growth. This agave thrives in full sun and well-draining, rocky or sandy soils, requiring minimal water once established. It is perfect for xeriscaping, offering both visual appeal and resilience in low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "7-11",
        matureSize: "3' x 5'",
        native: false
    },
    {
        id: 23,
        name: "Twin-Flower Agave",
        scientificName: "Agave geminiflora",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Xeric/cactus/agave/Agave_geminiflora_-_Twin-flowered_agave_01-1.jpg",
        description: "Twin flower agave is a unique and elegant succulent native to Mexico, known for its slender, arching leaves that form a graceful rosette. The leaves are typically green with a hint of blue and have smooth edges, creating a soft, flowing appearance. In late spring to early summer, twin flower agave produces a tall, multi-branched flower stalk that can reach up to 12 feet, with clusters of yellowish-green flowers. Unlike many agaves, it tends to produce two flowers per stalk, which is how it gets its name. This agave thrives in full sun and well-draining, rocky or sandy soils, requiring minimal water once established. Perfect for xeriscaping, twin flower agave adds both beauty and resilience to low-water landscapes.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "8-11",
        matureSize: "2' x 2'",
        native: false
    },

    ],
aloes : [
    {
        id: 1,
        name: "Hardy Aloe",
        scientificName: "Aloe maculata",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/vines-1747224_1920.jpg",
        description: "Grape Vines are a popular fruit crop for New Mexico, thriving in the state’s sunny, dry climate. Grapevines need full sun and well-draining, slightly sandy soil to produce high-quality fruit. They are ideal for both home gardeners and commercial growers, particularly in the southern and central regions, where the extended growing season supports their long ripening period. Grapes require regular irrigation, especially during the hot summer months, but they are fairly drought-tolerant once established. Varieties like Concord, Thompson Seedless, and Zinfandel are commonly grown, offering a range of flavors for fresh eating, drying, or wine production.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-10",
        matureSize: "1' x 1'",
        native: false
    },
    {
        id: 1,
        name: "Torch Aloe",
        scientificName: "Aloe marlothii",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/vines-1747224_1920.jpg",
        description: "Grape Vines are a popular fruit crop for New Mexico, thriving in the state’s sunny, dry climate. Grapevines need full sun and well-draining, slightly sandy soil to produce high-quality fruit. They are ideal for both home gardeners and commercial growers, particularly in the southern and central regions, where the extended growing season supports their long ripening period. Grapes require regular irrigation, especially during the hot summer months, but they are fairly drought-tolerant once established. Varieties like Concord, Thompson Seedless, and Zinfandel are commonly grown, offering a range of flavors for fresh eating, drying, or wine production.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-10",
        matureSize: "1' x 1'",
        native: false
    },
    {
        id: 1,
        name: "Blue Elf Aloe",
        scientificName: "Aloe striata",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/vines-1747224_1920.jpg",
        description: "Grape Vines are a popular fruit crop for New Mexico, thriving in the state’s sunny, dry climate. Grapevines need full sun and well-draining, slightly sandy soil to produce high-quality fruit. They are ideal for both home gardeners and commercial growers, particularly in the southern and central regions, where the extended growing season supports their long ripening period. Grapes require regular irrigation, especially during the hot summer months, but they are fairly drought-tolerant once established. Varieties like Concord, Thompson Seedless, and Zinfandel are commonly grown, offering a range of flavors for fresh eating, drying, or wine production.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-10",
        matureSize: "1' x 1'",
        native: false
    },
    {
        id: 1,
        name: "Short leaf Aloe",
        scientificName: "Aloe nobilis",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/vines-1747224_1920.jpg",
        description: "Grape Vines are a popular fruit crop for New Mexico, thriving in the state’s sunny, dry climate. Grapevines need full sun and well-draining, slightly sandy soil to produce high-quality fruit. They are ideal for both home gardeners and commercial growers, particularly in the southern and central regions, where the extended growing season supports their long ripening period. Grapes require regular irrigation, especially during the hot summer months, but they are fairly drought-tolerant once established. Varieties like Concord, Thompson Seedless, and Zinfandel are commonly grown, offering a range of flavors for fresh eating, drying, or wine production.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
        waterRequirement: "Low Water",
        hardinessZones: "4-10",
        matureSize: "1' x 1'",
        native: false
    },
]
    


} 

export default cactusdata
const fruitdata = {
apples: [
      {
        id: 1,
        name: "Fuji Apple",
        scientificName: "Malus domestica 'Fuji'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/apples-2788599_1920.jpg",
        description: "The Fuji Apple tree thrives in New Mexico’s sunny climate with well-draining soil and moderate watering. It produces crisp, sweet apples in fall but requires a pollinator like Gala or Granny Smith. While heat-tolerant, some afternoon shade helps in hotter areas. A great choice for home orchards, it rewards gardeners with delicious fruit.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "15' x 15'",
        native: false
      },
      {
        id: 2,
        name: "Honeycrisp Apple",
        scientificName: "Malus domestica 'Honeycrisp'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/apple-4055926_1920.jpg",
        description: "The Honeycrisp Apple tree does well in New Mexico’s full sun with well-draining soil and regular watering. It produces crisp, juicy apples in fall but needs a pollinator like Fuji or Gala. We suggest this tree for the northern and central parts of the state.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "4-8",
        matureSize: "15' x 15' ",
        native: false
      },
      {
        id: 3,
        name: "Granny Smith Apple",
        scientificName: "Malus domestica 'Granny Smith'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/grannysmith.jpg",
        description: "The Granny Smith Apple tree grows well in New Mexico’s full sun with well-draining soil and moderate watering. It produces tart, green apples in fall and serves as a great pollinator for other varieties. This variety is a bit more heat tolerant and is suggested for the southern parts of the state.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-8",
        matureSize: "15' x 15' ",
        native: false
      },
    ],
  
    cherries: [
      {
        id: 4,
        name: "Bing Cherry",
        scientificName: "Prunus avium 'Bing'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/cherry-2369275_1920.jpg",
        description: "The Bing Cherry tree thrives in New Mexico’s sunny climate with well-draining soil and deep, regular watering. It produces large, sweet, dark red cherries in late spring but requires a pollinator like Rainier or Montmorency for fruiting. While somewhat heat-tolerant, it benefits from protection against late frosts in colder areas.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-8",
        matureSize: "20' x 20'",
        native: false
        
      },
      {
        id: 5,
        name: "Rainer Cherry",
        scientificName: "Prunus avium 'Rainier'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/cherry-212601_1920.jpg",
        description: "The Rainier Cherry tree  is prized for its exceptionally sweet, yellow-red fruit, ripening in early summer. It needs full sun, well-draining soil, and consistent watering, especially in dry climates. Rainier cherries require a pollinator like Bing or Montmorency and may need bird protection, as their fruit is highly desirable.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-8",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 6,
        name: "Monmorency Cherry",
        scientificName: "Prunus cerasus 'Montmorency'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/cherry.jpg",
        description: "The Montmorency Cherry tree is a hardy, self-pollinating sour cherry ideal for pies and preserves. It tolerates New Mexico’s sun but prefers deep, infrequent watering and winter chill for best fruiting. Blooming early, it may need frost protection in colder areas but remains a reliable producer for home orchards.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "4-8",
        matureSize: "15' x 15'",
        native: false
      },
    ],
  
    peaches: [
      {
        id: 7,
        name: "Redhaven Peach",
        scientificName: "Prunus persica 'Redhaven'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/peaches-3529802_1920.jpg",
        description: "The Redhaven Peach tree  is a reliable, cold-hardy variety that thrives in New Mexico’s full sun with well-draining soil and regular watering. It produces sweet, juicy, medium-sized peaches in mid-summer and is self-pollinating, making it an excellent choice for home orchards. Proper pruning helps maintain healthy fruit production.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: '5-8',
        matureSize: "15' x 15'",
        native: false
      },
      {
        id: 8,
        name: "Elberta Peach",
        scientificName: "Prunus persica 'Elberta'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/peach-2632182_1920.jpg",
        description: "The Elberta Peach tree is a classic freestone variety known for its large, sweet fruit and vigorous growth. It requires full sun, deep watering, and well-draining soil to thrive in New Mexico’s climate. Blooming early, it may need frost protection in colder areas but rewards growers with a heavy summer harvest.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "5-9",
        matureSize: "20' x 20'",
        native: false
      },
      {
        id: 9,
        name: "Contender Peach",
        scientificName: "Prunus persica 'Contender'",
        image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/peach.jpg",
        description: "The Contender Peach tree is an exceptionally cold-hardy variety that thrives in New Mexico’s variable climate. It produces firm, sweet peaches in late summer and is self-pollinating. With proper watering and pruning, it offers a reliable harvest, even in regions with late frosts.",
        sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        sunRequirement: "Full Sun",
        waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        waterRequirement: "Medium Water",
        hardinessZones: "4-8",
        matureSize: "15' x 15'",
        native: false
      },
    ],
    nectarines: [
        {
          id: 10,
          name: "Fantasia Nectarine",
          scientificName: "Prunus persica var. nucipersica 'Fantasia'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/nectarines-196805_1920.jpg",
          description: "The Fantasia Nectarine tree thrives in New Mexico’s full sun with well-draining soil and regular watering. It produces large, sweet, tangy nectarines in mid to late summer and is self-pollinating. This variety benefits from annual pruning to maintain fruit quality and tree health.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "5-9",
          matureSize: "20' x 20'",
          native: false
        },
        {
          id: 11,
          name: "Artic Star Nectarine",
          scientificName: "Prunus persica var. nucipersica 'Arctic Star'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/nectarine.jpg",
          description: "The Arctic Star Nectarine tree is an early-ripening, low-acid variety with exceptionally sweet, white-fleshed fruit. It performs well in New Mexico’s heat but needs consistent watering and well-draining soil. Self-pollinating and highly productive, it’s a great choice for home orchards.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-10",
          matureSize: "15' x 15'",
          native: false
        },
      ],
      apricots: [
        {
            id: 12,
            name: "Tilton Apricot",
            scientificName: "Prunus armeniaca 'Tilton'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/apricots-6396505_1920.jpg",
            description: "The Tilton Apricot tree is a hardy, late-blooming variety that thrives in New Mexico’s central and southern regions. It requires 600-700 chill hours and is self-pollinating, though yields improve with another apricot nearby. Tilton produces rich, tangy fruit in mid-summer, excellent for fresh eating and drying. Plant in well-draining soil, water deeply but infrequently, and provide frost protection in northern areas where late freezes can damage blossoms.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "20' x 20'",
            native: false
          },
        {
            id: 13,
            name: "Surecrop Apricot",
            scientificName: "Prunus armeniaca 'Surecrop'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/apricots-6396524_1920.jpg",
            description: "The Surecrop Apricot tree is a reliable producer suited for New Mexico’s warmer regions, requiring 500-600 chill hours. It’s self-pollinating but benefits from cross-pollination for a heavier crop. Surecrop bears medium to large, sweet fruit in early to mid-summer and thrives in full sun with deep, infrequent watering. This variety tolerates heat well but may need protection from late spring frosts in northern areas.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 14,
            name: "Blenhiem Apricot",
            scientificName: "Prunus armeniaca 'Blenheim'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/apricots-824626_1920.jpg",
            description: "The Blenheim Apricot tree flourishes in New Mexico’s warmer valleys and lower elevations, requiring 400-600 chill hours. It produces sweet, aromatic fruit in early summer but is sensitive to late frosts, making it best for central and southern parts of the state. While self-pollinating, planting another variety nearby can enhance fruit production. Regular pruning, deep watering, and well-draining soil ensure a healthy, productive tree.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "20' x 20'",
            native: false
          },

      ],
      plums: [
        {
            id: 15,
            name: "Santa Rosa Plum",
            scientificName: "Prunus salicina 'Santa Rosa'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/plum-4344623_1920.jpg",
            description: "The Santa Rosa Plum tree is a heat-tolerant, self-pollinating variety that thrives in New Mexico’s central and southern regions. It produces large, juicy, red-skinned fruit with sweet-tart yellow flesh in mid-summer. While it can fruit on its own, planting another Japanese plum nearby improves yields. It needs full sun, well-draining soil, and deep watering to withstand dry conditions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 16,
            name: "Methley Plum",
            scientificName: "Prunus salicina 'Methley'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/methleyplum.jpg",
            description: "The Methley Plum tree is a self-fertile Japanese plum that does well in New Mexico’s warm, sunny climate. It bears small to medium, deep purple fruit with sweet, red flesh in early summer. Methley thrives with full sun, deep but infrequent watering, and well-draining soil. It’s more tolerant of humidity than other plums and attracts pollinators with its fragrant spring blossoms.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "15' x 15'",
            native: false
          },
          {
            id: 17,
            name: "Stanley Plum",
            scientificName: "Prunus domestica 'Stanley'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/plums-3628167_1920.jpg",
            description: "The Stanley Plum tree is a European plum ideal for northern and central New Mexico. It produces sweet, freestone fruit in late summer, excellent for fresh eating or drying into prunes. Unlike Japanese plums, Stanley benefits from another European plum nearby for best yields. Plant in full sun with deep watering and protect early blooms from late frosts in colder areas.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "15' x 15'",
            native: false
          },

      ],
      pears: [
        {
            id: 18,
            name: "Bartlett Pear",
            scientificName: "Pyrus communis 'Bartlett'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pear-2297977_1920.jpg",
            description: "The Bartlett Pear tree is a classic, self-pollinating variety that thrives in New Mexico’s sunny climate. It produces sweet, juicy pears in late summer and is a reliable choice for home orchards. For optimal fruiting, plant another pear variety nearby to boost yields. Bartletts prefer full sun, well-draining soil, and regular watering, with some frost protection for early blooms in colder regions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "20' x 20'",
            native: false
          },
        {
            id: 19,
            name: "D'Anjou Pear",
            scientificName: "Pyrus communis 'D'Anjou'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pear-3560106_1920.jpg",
            description: "The D'Anjou Pear tree is a versatile pear variety known for its smooth, sweet flesh and green skin that ripens in fall. It performs well in New Mexico’s full sun, requiring well-draining soil and deep watering. While self-pollinating, planting another pear variety, like Bartlett, will improve fruit production. D'Anjou pears are cold-hardy, making them suitable for higher elevations and areas with colder winters.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "5-9",
            matureSize: "20' x 20'",
            native: false
          },
          {
            id: 20,
            name: "Kieffer Pear",
            scientificName: "Pyrus communis 'Kieffer'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pear.jpg",
            description: "The Kieffer Pear tree is a hardy, disease-resistant variety well-suited for New Mexico’s warm climate. It produces crisp, tart pears in late summer and is perfect for canning or preserves. Kieffer pears are self-pollinating but benefit from cross-pollination with another pear variety to maximize fruit yield. They require full sun, well-draining soil, and deep watering, along with frost protection for early blooms in colder areas.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "4-9",
            matureSize: "20' x 20'",
            native: false
          },

      ],
      figs: [
        {
          id: 21,
          name: "Celeste Fig",
          scientificName: "Ficus carica 'Celeste'",
          image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/figs-7421496_1920.jpg",
          description: "The Celeste Fig tree is a popular variety for New Mexico gardeners, known for its small, sweet, and purple-brown fruit. It thrives in the state's hot, dry climate, requiring full sun and well-draining soil. Celeste figs are relatively low-maintenance and drought-tolerant once established but benefit from deep, infrequent watering. This variety is also more cold-hardy compared to others, making it suitable for areas with occasional frost.",
          sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
          sunRequirement: "Full Sun",
          waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
          waterRequirement: "Medium Water",
          hardinessZones: "7-9",
          matureSize: "10' x 10'",
          native: false
        },
        {
            id: 22,
            name: "Brown Turkey Fig",
            scientificName: "Ficus carica 'Brown Turkey'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/fig.jpg",
            description: "The Brown Turkey Fig tree is another excellent choice for New Mexico's climate. It produces medium to large, sweet, brown-purple figs with a rich flavor. Brown Turkey figs need full sun and well-draining soil, along with regular watering, especially during the hot summer months. While they are somewhat drought-tolerant, they perform best with consistent moisture. This variety is also more adaptable to colder temperatures, making it a reliable option for both lower and higher elevations in New Mexico.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "7-9",
            matureSize: "15' x 15'",
            native: false
          },
       
      ],
      pomegranates: [
        {
            id: 23,
            name: "Wonderful Pomegranates",
            scientificName: "Punica granatum 'Wonderful'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pomegranate-2825556_1920.jpg",
            description: "The Wonderful Pomegranate tree is the most popular variety for New Mexico gardeners, known for its large, vibrant red fruit with sweet-tart arils. It thrives in New Mexico’s sunny, dry climate and requires full sun, well-draining soil, and deep watering. Pomegranates are drought-tolerant once established but perform best with consistent moisture during the growing season. The Wonderful variety is heat-tolerant and hardy enough for the state’s warmer regions, making it an excellent choice for home orchards.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "7-10",
            matureSize: "12' x 12'",
            native: false
          },
        {
            id: 24,
            name: "Russain 26 Pomegranates",
            scientificName: "Punica granatum 'Russian 26'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/fruit-3036563_1920.jpg",
            description: "The Russian 26 Pomegranate tree is a cold-hardy variety perfect for New Mexico’s more northern regions. It produces smaller, sweet-tasting fruit and is more tolerant of freezing temperatures compared to other pomegranate varieties. Russian 26 thrives in full sun and well-draining soil, and while it’s drought-tolerant once established, it benefits from regular watering during the growing season. This variety is ideal for areas with colder winters, offering gardeners the chance to grow pomegranates even in more temperate climates.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Low-water.png",
            waterRequirement: "Low Water",
            hardinessZones: "6-9",
            matureSize: "12' x 12'",
            native: false
          },
      ],
      almonds: [
        {
            id: 25,
            name: "Mission Almond",
            scientificName: "Prunus dulcis 'Mission'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/almond-tree-5951352_1920.jpg",
            description: "One of the most common varieties, the Mission Almond sometimes known as the Texas Almond, is a great almond for Southern New Mexico, but think twice before planting in the Albuquerque area. Although the most popular, almonds are notoriously early bloomers, with this variety being a VERY early bloomer. With late freezes in Albuquerque and Santa Fe, you may seldom get fruit.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "7-9",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 26,
            name: "Bounty Almond",
            scientificName: "Prunus dulcis 'Bounty'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/almond-5530630_1920.jpg",
            description: "The Bounty Almond tree is a hardy, self-pollinating variety that thrives in New Mexico’s sunny and dry climate. It produces large, sweet almonds in late summer, perfect for snacking or baking. Bounty almonds need full sun, well-draining soil, and deep, infrequent watering to thrive. While it’s drought-tolerant once established, regular watering during the growing season helps produce a healthier crop. This variety is well-suited for warmer regions of New Mexico, particularly the southern areas.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "7-9",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 27,
            name: "All-In-One Almond",
            scientificName: "Prunus dulcis 'All-in-One'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/almonds-2667060_1920.jpg",
            description: "The All-in-One Almond tree is a unique, self-pollinating variety that can grow in a wide range of climates, making it perfect for New Mexico’s diverse elevations. It produces sweet, high-quality almonds and is well-suited for areas with both hot summers and cold winters. All-in-One requires full sun, well-draining soil, and deep watering to produce a healthy crop. This variety is also more adaptable to colder temperatures than other almond trees, making it a great choice for gardeners in northern regions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "7-9",
            matureSize: "15' x 15'",
            native: false
          },
      ],
      pecans: [
        {
            id: 28,
            name: "Western Schley Pecans",
            scientificName: "Carya illinoinensis 'Western Schley'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/nuts-315687_1920.jpg",
            description: "The Western Schley Pecan tree is a popular variety known for its large, thin-shelled nuts with sweet, buttery flavor. It thrives in New Mexico’s warm climate, requiring full sun, deep well-draining soil, and regular watering to produce a healthy crop. This variety is best suited for the southern and central regions of the state, where the growing season is long. Western Schley pecans are also known for their resistance to certain diseases and pests, making them a reliable choice for home orchards.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "6-9",
            matureSize: "40' x 40'",
            native: false
          },
        {
            id: 29,
            name: "Wichita Pecan",
            scientificName: "Carya illinoinensis 'Wichita'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/nuts-316146_1920.jpg",
            description: "The Wichita Pecan tree is another excellent choice for New Mexico gardeners, producing large, high-quality nuts with a rich, buttery flavor. Wichita trees require full sun and deep, well-draining soil, along with regular watering, especially during the growing season. They are more cold-hardy than other pecan varieties, making them suitable for northern areas of New Mexico. Wichita trees are also resistant to certain pests, which helps ensure a bountiful harvest year after year.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "6-9",
            matureSize: "40' x 40'",
            native: false
          },
        {
            id: 30,
            name: "Cheyenne Pecan",
            scientificName: "Carya illinoinensis 'Cheyenne'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pecan-tree-186328_1920.jpg",
            description: "The Cheyenne Pecan tree is a cold-hardy variety that performs well in New Mexico’s higher elevations and cooler regions. It produces medium-sized, flavorful pecans and is well-suited for areas with shorter growing seasons. Like other pecan varieties, Cheyenne needs full sun, deep well-draining soil, and regular watering. It is also resistant to several pests and diseases, making it an excellent choice for low-maintenance orchards in New Mexico’s northern and central regions.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "6-9",
            matureSize: "40' x 40'",
            native: false
          },
      ],
      pistachios: [
        {
            id: 31,
            name: "Kerman Pistachios",
            scientificName: "Pistacia vera 'Kerman'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pistachios-1540123_1920.jpg",
            description: "The Kerman Pistachio tree  is the most widely grown pistachio variety in New Mexico and is prized for its large, flavorful nuts. It thrives in the state’s hot, dry climate and requires full sun and well-draining, sandy loam soil. Kerman trees are dioecious, meaning you need both male and female trees for pollination, but they are highly productive once established. They are drought-tolerant and thrive with minimal watering once mature, though regular irrigation helps during the growing season. This variety is best suited for New Mexico’s southern and central regions, where the growing season is long.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "7-10",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 32,
            name: "Peters Pistachios",
            scientificName: "Pistacia vera 'Peters'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pistachios-1540134_1920.jpg",
            description: "The Peters Pistachio tree is another excellent pistachio variety well-suited for New Mexico’s climate. It produces large, high-quality nuts with a sweet, rich flavor. Peters trees are also dioecious, requiring a male tree for pollination, but they are incredibly productive. They prefer full sun and well-draining soil, with deep, infrequent watering. This variety is ideal for southern New Mexico, where the warmer temperatures provide the necessary heat for the pistachios to fully mature. Peters trees are a great choice for gardeners seeking a reliable and bountiful pistachio crop.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "7-10",
            matureSize: "15' x 15'",
            native: false
          },
        {
            id: 33,
            name: "Kaleh Ghouchi Pistachios",
            scientificName: "Pistacia vera 'Kalehgouchi'",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/pistachio-1137886_1920.jpg",
            description: "The Kalehgouchi Pistachio tree is a hardy pistachio variety that’s well-suited for New Mexico’s hot, arid conditions. It produces medium-sized, flavorful pistachios and requires both male and female trees for successful pollination. Kalehgouchi pistachios are especially prized for their resistance to cold, making them a good option for areas with cooler winters. Like other pistachios, they require full sun, well-draining soil, and deep watering, especially in the first few years after planting. This variety is a great choice for northern parts of New Mexico and for gardeners who want a hardy, productive pistachio tree.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/High-water.png",
            waterRequirement: "High Water",
            hardinessZones: "7-10",
            matureSize: "15' x 15'",
            native: false
          },
      ],
      others: [
        {
            id: 34,
            name: "JuJubes (Chinese Date)",
            scientificName: "Ziziphus jujuba",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/autumn-3400000_1920.jpg",
            description: "Jujube Trees are well-suited to New Mexico’s hot, dry climate. Known for their drought tolerance and ability to thrive in poor soil conditions, jujubes are a low-maintenance fruit tree that require full sun and well-draining soil. These trees produce small, sweet fruits that resemble dates when dried, with fresh fruit offering a crisp, apple-like texture. Jujubes are excellent for southern and central New Mexico, where the long growing season and warm temperatures help the fruit mature. Once established, these trees are highly resilient and require minimal care beyond occasional watering.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "6-10",
            matureSize: "20' x 10'",
            native: false
          },
        {
            id: 35,
            name: "Persimmons",
            scientificName: "Diospyros spp.",
            image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/persimmons-6735325_1920.jpg",
            description: "Persimmon Trees grow well in New Mexico’s warmer regions, especially in areas with long summers and mild winters. Both American and Asian varieties can thrive, with the Fuyu persimmon being a popular choice due to its non-astringent fruit that can be eaten while still firm. Persimmons require full sun, well-draining soil, and regular watering to produce sweet, flavorful fruit. While relatively low-maintenance, they benefit from some frost protection during early spring bloom and need consistent moisture during the growing season for optimal fruiting.",
            sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
            sunRequirement: "Full Sun",
            waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
            waterRequirement: "Medium Water",
            hardinessZones: "4-9",
            matureSize: "50' x 30'",
            native: false
          },
        // {
        //     id: 36,
        //     name: "Grapes",
        //     scientificName: "Vitis vinifera",
        //     image: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/Trees/fruit/vines-1747224_1920.jpg",
        //     description: "Grape Vines are a popular fruit crop for New Mexico, thriving in the state’s sunny, dry climate. Grapevines need full sun and well-draining, slightly sandy soil to produce high-quality fruit. They are ideal for both home gardeners and commercial growers, particularly in the southern and central regions, where the extended growing season supports their long ripening period. Grapes require regular irrigation, especially during the hot summer months, but they are fairly drought-tolerant once established. Varieties like Concord, Thompson Seedless, and Zinfandel are commonly grown, offering a range of flavors for fresh eating, drying, or wine production.",
        //     sunIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/FullSun.png",
        //     sunRequirement: "Full Sun",
        //     waterIcon: "https://jericho-content.nyc3.cdn.digitaloceanspaces.com/VARIOUS/SEO_photos/plant_card_icons/Medium-water.png",
        //     waterRequirement: "Medium Water",
        //     hardinessZones: "4-10",
        //     matureSize: "50-100 sq ft",
        //     native: false
        //   },
      ],
  
  };
  
  export default fruitdata;
  